import { polygon, polygonMumbai } from 'wagmi/chains';
import { CurrencyType } from './types/currency-type';

export const CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;

export const DAC_PRICE = process.env.DAC_PRICE ? Number(process.env.DAC_PRICE) : 19.0;
export const SILVER_PRICE = process.env.SILVER_PRICE ? Number(process.env.SILVER_PRICE) : 390.0;
export const GOLD_PRICE = process.env.GOLD_PRICE ? Number(process.env.GOLD_PRICE) : 3900.0;
export const PLATINUM_PRICE = process.env.PLATINUM_PRICE ? Number(process.env.PLATINUM_PRICE) : 18500.0;

// Set your amount limits: Use float for decimal currencies and
// Integer for zero-decimal currencies: https://stripe.com/docs/currencies#zero-decimal.
export const CURRENCY = process.env.NEXT_PUBLIC_CURRENCY ?? CurrencyType.EUR;

export const chainIdentifier = process.env.NEXT_PUBLIC_ENVIRONMENT === 'DEV' ? polygonMumbai.id : polygon.id;

export const preOrderContractAddress =
	process.env.NEXT_PUBLIC_ENVIRONMENT === 'DEV'
		? process.env.NEXT_PUBLIC_PREORDER_CONTRACT_ADDRESS_DEV
		: process.env.NEXT_PUBLIC_PREORDER_CONTRACT_ADDRESS_PROD;

if (preOrderContractAddress === undefined) {
	throw new Error('Expected preOrderContractAddress address');
}

import { financial } from './helpers';
import { CurrencyType } from './types/currency-type';

function numberFormat(currency: CurrencyType): Intl.NumberFormat {
	let language = ['fr-FR'];
	if (currency === CurrencyType.USD) {
		language = ['en-US'];
	}
	return new Intl.NumberFormat(language, {
		style: 'currency',
		currency: currency.toLowerCase(),
		currencyDisplay: 'symbol',
	});
}
export function formatAmountForDisplay(amount: number, currency: CurrencyType): string {
	let nf = numberFormat(currency);
	return nf.format(amount);
}

export function formatAmountForStripe(amount: number, currency: CurrencyType): number {
	let nf = numberFormat(currency);
	const parts = nf.formatToParts(amount);
	let zeroDecimalCurrency: boolean = true;
	for (let part of parts) {
		if (part.type === 'decimal') {
			zeroDecimalCurrency = false;
		}
	}
	return zeroDecimalCurrency ? amount : Math.round(amount * 100);
}

export function formatAmountFromStripe(amount: number, currency: CurrencyType): number {
	let nf = numberFormat(currency);
	const parts = nf.formatToParts(amount);
	let zeroDecimalCurrency: boolean = true;
	for (let part of parts) {
		if (part.type === 'decimal') {
			zeroDecimalCurrency = false;
		}
	}
	// @ts-ignore
	return zeroDecimalCurrency ? amount : financial(amount / 100);
}

export function getFees(price: number): number {
	// Commented out because we don't want to use this anymore
	/*if (price >= 0.5 && price <= 100) {
		return 2;
	}
	if (price > 100 && price <= 300) {
		return 3;
	}
	if (price > 300 && price <= 330) {
		return 4;
	}
	if (price > 330 && price < 400) {
		return 5;
	}
	if (price >= 400) {
		return 6;
	}*/
	return 0;
}

import { CircularProgress, Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'next-i18next';
import { PaymentIntent } from '@stripe/stripe-js';
import { useSession } from 'next-auth/react';
import getStripe from '../../../utils/get-stripe';
import ElementsForm from '../../ElementsForm';
import FormBox from '../../../components/FormBox';
import payStyles from './Pay.style';
import { CurrencyType } from '../../../utils/types/currency-type';

interface PayProps {
	itemPay: Item;
	currency: CurrencyType;
	paymentIntent?: PaymentIntent | null;
}

export default function Pay({ itemPay, currency, paymentIntent }: PayProps) {
	const { t } = useTranslation(['home', 'payment', 'header']);
	const { data: session } = useSession();

	return (
		<FormBox>
			<Stack sx={payStyles.contained}>
				{paymentIntent && paymentIntent.client_secret ? (
					<Elements
						stripe={getStripe()}
						key={paymentIntent.client_secret}
						options={{
							fonts: [{ family: 'RigidSquare', src: 'url(/fonts/RigidSquare/rigidsquareNEW.ttf)' }],
							appearance: {
								theme: 'stripe',
								labels: 'above',
								variables: {
									colorWarning: 'tomato',
									colorDanger: 'tomato',
									colorIconCardError: 'tomato',
									colorIconCardCvcError: 'tomato',
									colorWarningText: 'tomato',
									colorDangerText: 'tomato',
									colorBackgroundText: 'tomato',
									colorIcon: 'whitesmoke',
									colorBackground: '#2d3748',
									colorPrimary: 'whitesmoke',
									colorPrimaryText: 'whitesmoke',
									colorText: 'whitesmoke',
									colorTextSecondary: 'whitesmoke',
									colorTextPlaceholder: '#727F96',
									colorIconTab: 'whitesmoke',
									colorLogo: 'dark',
									fontFamily: 'RigidSquare, Roboto, Open Sans, Segoe UI, sans-serif',
								},
							},
							clientSecret: paymentIntent.client_secret,
						}}
					>
						<ElementsForm paymentIntent={paymentIntent} email={session?.user?.email as string} />
					</Elements>
				) : (
					<>
						<CircularProgress />
						<div>{t('loading', { ns: 'payment' })}...</div>
					</>
				)}
			</Stack>
		</FormBox>
	);
}

import React, { ReactElement, useEffect, useState } from 'react';
import { i18n, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y, EffectCoverflow } from 'swiper/modules';
import { Button, Stack, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import homeStyle from '../Home.style';
import certImg from './img/certificatbg.jpg';
import { colorOr } from '../../../themes/theme';
import PricesTag from '../../PricesTag/PricesTag';
import { ProductType } from '../../../utils/types/product-type';

interface CarrouselItem {
	key: number;
	content: ReactElement;
}

interface CarouselProps {
	items: Item[];
	id: number;
	onSelect: (item: Item) => void;
	isPurchased: boolean;
}

function Carousel({ id, items, onSelect, isPurchased = false }: CarouselProps) {
	const { t } = useTranslation(['home', 'header']);
	const saleIsActive = process.env.NEXT_PUBLIC_SALE_IS_ACTIVE === 'true';

	const [arCarrousel, setArCarrousel] = useState<CarrouselItem[]>([]);
	const [pieceId, setPieceId] = useState<number>();
	const [showDiv, setShowDiv] = useState(false);
	const [certRefs, setCertRefs] = useState<HTMLImageElement[]>([]);
	const [videoRefs, setVideoRefs] = useState<HTMLVideoElement[]>([]);
	const [infoRefs, setInfoRefs] = useState<HTMLDivElement[]>([]);

	useEffect(() => {
		const arItem = items.map((item) => {
			return {
				key: item.id,
				content: (
					<Stack sx={{ flexDirection: 'column', position: 'absolute' }}>
						<Stack sx={{ position: 'relative' }}>
							<Stack
								sx={{
									zIndex: 999,
									cursor: isPurchased ? 'help' : 'cursor',
									position: 'absolute',
									left: '0',
									right: '0',
									top: '0%',
									width: '100%',
									height: '12%',
								}}
								onClick={() => {
									if (certRefs[item.id]) {
										if (certRefs[item.id].style.transform === 'translate(-50%) translateZ(100px) rotateY(0deg)') {
											certRefs[item.id].style.opacity = '0';
											certRefs[item.id].style.transform = 'translate(-50%) translateZ(100px) rotateY(90deg)';
										} else {
											certRefs[item.id].style.opacity = '1';
											certRefs[item.id].style.transform = 'translate(-50%) translateZ(100px) rotateY(0deg)';
										}
									}
								}}
							>
								{/*<Image
									alt="bar"
									src={BarImg}
									width={0}
									height={0}
									priority={false}
									style={{
										position: 'absolute',
										left: 'calc(50% - 90px)',
										top: '0%',
										width: '380px',
										cursor: 'pointer',
										opacity: 0,
									}}
								/>*/}
							</Stack>
							<Stack
								sx={{
									zIndex: 10,
									position: 'absolute',
									right: '10px',
									bottom: '9.5%',
								}}
							>
								{isPurchased && (
									<Typography fontSize={isMobile ? '0.8em' : '0.9em'} color={'platinum'}>
										{t('tokenID', { ns: 'home' })} {item.tokenId}
									</Typography>
								)}
							</Stack>

							{!item.imgUrl.endsWith('.mp4') ? (
								/*<Image
									alt={item.name}
									src={item.imgUrl}
									width={0}
									height={0}
									fill={false}
									priority={true}
									sizes="100vw"
									style={{
										objectFit: 'contain',
										maxHeight: '1080px',
										maxWidth: '1920px',
										minHeight: '390px',
										minWidth: '250px',
										height: 'auto',
										width: '100%',
										borderRadius: '2px',
										margin: 'auto',
									}}
								/>*/
								<img alt={item.name} src={item.imgUrl} />
							) : (
								<video
									poster={item.poster}
									ref={(rv) => {
										videoRefs[item.id] = rv as HTMLVideoElement;
										setVideoRefs(videoRefs);
									}}
									onClick={() => {
										if (videoRefs[item.id]) {
											if (videoRefs[item.id].paused) {
												void videoRefs[item.id].play();
											} else {
												videoRefs[item.id].pause();
											}
										}
									}}
									style={{ cursor: 'pointer' }}
								>
									<source src={item.imgUrl} type="video/mp4" />
								</video>
							)}

							{/* Metadata Info */}
							{isPurchased && (
								<Stack
									ref={(r) => {
										certRefs[item.id] = r as HTMLImageElement;
										setCertRefs(certRefs);
									}}
									className="cert"
									sx={{
										position: 'absolute',
										top: '30px',
										width: '90%',
										height: '90%',
										margin: 'auto',
										zIndex: 100,
										left: '50%',
										transform: 'translate(-50%) translateZ(100px) rotateY(90deg)',
										transition: 'opacity 1s ease, transform 1s ease',
										opacity: 0,
										fontSize: '13px',
										color: '#5f5f60bd',
										cursor: 'pointer',
									}}
									onClick={() => {
										if (certRefs[item.id]) {
											if (certRefs[item.id].style.transform === 'translate(-50%) translateZ(100px) rotateY(0deg)') {
												certRefs[item.id].style.opacity = '0';
												certRefs[item.id].style.transform = 'translate(-50%) translateZ(100px) rotateY(90deg)';
											} else {
												certRefs[item.id].style.opacity = '1';
												certRefs[item.id].style.transform = 'translate(-50%) translateZ(100px) rotateY(0deg)';
											}
										}
									}}
								>
									<img
										src={certImg.src}
										alt="certificat"
										style={{
											width: '100%',
											height: '70vh',
											margin: 'auto',
										}}
									/>
									<Stack
										sx={{
											position: 'absolute',
											height: '100%',
											width: '100%',
											padding: '5px',
											boxSizing: 'border-box',
											justifyContent: 'flex-start',
										}}
									>
										<Typography
											sx={{
												fontWeight: 600,
												margin: '15px auto',
												fontSize: '18px',
												marginTop: '40px',
												textTransform: 'uppercase',
												color: 'grey',
											}}
										>
											{t('certificateAuthenticity')}
										</Typography>
										<img
											src={item.logo}
											style={{
												height: 'auto',
												maxHeight: '90px',
												maxWidth: '250px',
												display: 'block',
												position: 'relative',
												margin: '0 auto',
												filter: 'invert(50%) sepia(10%)',
											}}
											alt="logo"
										/>
										<img
											src="/images/logo_black.png"
											style={{
												height: '35px',
												display: 'block',
												position: 'relative',
												margin: '0 auto',
												marginTop: '15px',
												marginBottom: '10px',
												filter: 'invert(50%) sepia(10%)',
											}}
											alt="logo 101"
										/>
										<Stack
											sx={{
												flexDirection: 'row',
												fontSize: '12px',
											}}
										>
											<Stack sx={{ flex: 1, marginTop: '15px', padding: '0 15px' }}>
												<Stack
													sx={{
														textAlign: 'start',
														textTransform: 'uppercase',
														fontWeight: 'bold',
													}}
												>
													{t('title')}
												</Stack>
												<Stack>{item.name}</Stack>
											</Stack>

											<Stack sx={{ flex: 1, marginTop: '15px', padding: '0 15px' }}>
												<Stack
													sx={{
														textAlign: 'end',
														textTransform: 'uppercase',
														fontWeight: 'bold',
													}}
												>
													{t('artistYear')}
												</Stack>
												<Stack
													sx={{
														textAlign: 'end',
													}}
												>
													{item?.author} / {item?.year}
												</Stack>
											</Stack>
										</Stack>

										<Stack
											sx={{
												flexDirection: 'row',
												marginTop: '15px',
												padding: '0 15px',
											}}
										>
											<Stack sx={{ flex: 1, width: '50%' }}>
												<Stack
													sx={{
														textAlign: 'start',
														textTransform: 'uppercase',
														fontWeight: 'bold',
													}}
												>
													{t('addrOwner')}
												</Stack>
												<Typography sx={{ wordWrap: 'break-word' }} fontSize={'0.66rem'} color={'grey'} marginTop={1}>
													{item.addrOwner}
												</Typography>
											</Stack>

											<Stack sx={{ flex: 1, width: '50%' }}>
												<Stack
													sx={{
														textAlign: 'end',
														textTransform: 'uppercase',
														fontWeight: 'bold',
													}}
												>
													{t('contractAddr')}
												</Stack>
												<Typography
													fontSize={'0.66rem'}
													color={'grey'}
													marginTop={1}
													sx={{ wordWrap: 'break-word', textAlign: 'end' }}
												>
													{item.contractAddr}
												</Typography>
											</Stack>
										</Stack>

										<Stack sx={{ flex: 0, marginTop: '15px', padding: '0 15px' }}>
											<Stack
												sx={{
													textAlign: 'center',
													textTransform: 'uppercase',
													fontWeight: 'bold',
												}}
											>
												{t('tokenId')}
											</Stack>
											<Stack sx={{ wordWrap: 'break-word', textAlign: 'center' }} marginTop={1} fontSize={'1.1rem'}>
												{item.tokenId}
											</Stack>
										</Stack>
										<Stack
											sx={{
												flexDirection: 'row',
												marginTop: '15px',
												padding: '0 15px',
											}}
										>
											<Stack sx={{ flex: 1, width: '50%' }}>
												<Stack
													sx={{
														textAlign: 'start',
														textTransform: 'uppercase',
														fontWeight: 'bold',
													}}
												>
													{t('serialNumber')}
												</Stack>
												<Stack sx={{ wordWrap: 'break-word' }} marginTop={1}>
													{item.serialNumber ?? 'Original special series - Early Buyer'} -{' '}
													{ProductType[item.productType]}
												</Stack>
											</Stack>

											<Stack sx={{ flex: 1 }}>
												<Stack
													sx={{
														textAlign: 'end',
														textTransform: 'uppercase',
														fontWeight: 'bold',
													}}
												>
													{t('collectionNumber')}
												</Stack>
												<Stack sx={{ wordWrap: 'break-word', textAlign: 'end' }} marginTop={1}>
													#{item.number}
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							)}

							{/* Bar Slider Info */}
							<Stack
								ref={(ri) => {
									infoRefs[item.id] = ri as HTMLDivElement;
									setInfoRefs(infoRefs);
								}}
								className="slideInfo"
								sx={{
									zIndex: 100,
									cursor: 'help',
									width: '100%',
									height: '17%',
									position: 'absolute',
									color: 'rgba(0, 0, 0, 0)',
									transition: 'background-color 0.6s ease, opacity 0.6s ease, height 0.6s ease, color 0.6s ease',
									bottom: 0,
									padding: '15px',
									boxSizing: 'border-box',
									//textAlign: 'justify',
									overflow: 'auto',
								}}
								onClick={() => {
									if (infoRefs[item.id].style.height === '87%') {
										infoRefs[item.id].style.height = '17%';
										infoRefs[item.id].style.backgroundColor = 'rgba(0, 0, 0, 0)';
										infoRefs[item.id].style.color = 'rgba(0, 0, 0, 0)';
									} else {
										infoRefs[item.id].style.height = '87%';
										infoRefs[item.id].style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
										infoRefs[item.id].style.color = 'rgb(255,255,255)';
									}
								}}
							>
								{isPurchased ? (
									<p
										style={isMobile ? { fontSize: '0.8em' } : { fontSize: '1em' }}
										dangerouslySetInnerHTML={{
											__html: i18n?.language === 'fr' ? item.description : item?.descriptionEn ?? '',
										}}
									/>
								) : (
									<h2
										style={isMobile ? { fontSize: '0.8em' } : { fontSize: '1.2em' }}
										dangerouslySetInnerHTML={{
											__html: i18n?.language === 'fr' ? item.short : item?.shortEn ?? '',
										}}
									/>
								)}
							</Stack>
						</Stack>
						<Stack className="bar" sx={{ margin: '10px 0', position: 'relative', flexWrap: 'wrap' }}>
							<Stack>
								<Button
									variant="contained"
									onClick={() => {
										onSelect(item);
									}}
									disabled={!saleIsActive}
								>
									{t('buy')}
								</Button>
							</Stack>
							{saleIsActive && (
								<Stack sx={{ marginTop: '5px' }}>
									<Typography
										variant="h1"
										sx={{
											justifyContent: 'center',
											alignContent: 'center',
											alignItems: 'center',
											color: colorOr,
											whiteSpace: 'nowrap',
										}}
									>
										<PricesTag productType={ProductType.DAC} />
									</Typography>
									{/*<Typography
									className="point"
									sx={{
										justifyContent: 'center',
										alignContent: 'center',
										alignItems: 'center',
										color: '#dbdbdb',
										whiteSpace: 'nowrap',
										textDecoration: 'underline',
										cursor: 'pointer',
										fontSize: '13px',
									}}
									onClick={() => {
										setShowDiv(true);
									}}
								>
									{t('messagePriceButton')}
								</Typography>*/}
								</Stack>
							)}
						</Stack>
					</Stack>
				),
			};
		});

		const pos = items.findIndex((element) => element.id === id);
		setPieceId(pos);
		setArCarrousel(arItem);
	}, [id, items, onSelect, certRefs, t, videoRefs, infoRefs, isPurchased]);

	return (
		<Stack sx={homeStyle.carouselContent}>
			{arCarrousel.length > 0 && (
				<Swiper
					initialSlide={pieceId}
					style={{
						position: 'absolute',
						height: '100vh',
						left: 0,
						display: 'flex',
						width: '100vw',
						marginTop: '50px',
					}}
					modules={[Navigation, A11y, EffectCoverflow]}
					effect="coverflow"
					centeredSlides
					slidesPerView="auto"
					coverflowEffect={{
						rotate: 30,
						stretch: 0,
						depth: 300,
						modifier: 1,
						scale: 0.5,
						slideShadows: true,
					}}
					//pagination
					//navigation
					scrollbar={{ draggable: true }}
				>
					{arCarrousel.map((item, index) => (
						<SwiperSlide key={index}>{item.content}</SwiperSlide>
					))}
				</Swiper>
			)}
			{showDiv && (
				<Stack
					sx={{
						width: '225px',
						position: 'absolute',
						background: '#161c20',
						padding: '25px',
						border: '1px solid #ad9579',
						zIndex: '99',
						bottom: '25px',
						borderRadius: '20px',
						textAlign: 'center',
						paddingTop: '45px',
					}}
				>
					<CancelIcon
						sx={{
							right: '13px',
							position: 'absolute',
							top: '10px',
							color: '#ad9579',
							cursor: 'pointer',
						}}
						onClick={() => {
							setShowDiv(false);
						}}
					/>
					<Typography>{t('messagePrice')}</Typography>
				</Stack>
			)}
		</Stack>
	);
}

export default Carousel;

import { ProductType } from './types/product-type';
import { GOLD_BOX, PLATINUM_BOX, SILVER_BOX } from '../asset/monuments';

export function financial(x: string) {
	return Number.parseFloat(x).toFixed(2);
}

function parsePreOrderId(id: string) {
	let productType = ProductType.DAC;
	let monumentId = parseInt(id[0]);
	let dacId = parseInt(id[1]);
	// If length of id is 4, it means it's a box
	if (id.length === 4) {
		if (id === '1111') {
			productType = ProductType.SILVER;
			dacId = 1111;
		}
		if (id === '2222') {
			productType = ProductType.GOLD;
			dacId = 2222;
		}
		if (id === '3333') {
			productType = ProductType.PLATINUM;
			dacId = 3333;
		}
	}
	return { monumentId, dacId, productType };
}
export function parsePreOrders(preOrders: Array<any> | null, monuments: Piece[] | null) {
	if (!preOrders || !monuments) return null;

	// Map preOrders to nfts
	let myNfts: Piece[] = [];
	let myBoxes: Item[] = [];
	preOrders.forEach((preOrder) => {
		const { monumentId, dacId, productType } = parsePreOrderId(preOrder.id);
		if (productType === ProductType.SILVER) {
			myBoxes.push({ ...SILVER_BOX, number: preOrder.tokenId });
		}
		if (productType === ProductType.GOLD) {
			myBoxes.push({ ...GOLD_BOX, number: preOrder.tokenId });
		}
		if (productType === ProductType.PLATINUM) {
			myBoxes.push({ ...PLATINUM_BOX, number: preOrder.tokenId });
		}

		const monument = monuments.find((m) => m.id === monumentId);
		if (monument && productType === ProductType.DAC) {
			// Check if monument already exists in myNfts
			const monumentExistingInMyNfts = myNfts.find((m) => m.id === monumentId);
			let existingItems: Item[] = [];
			if (!monumentExistingInMyNfts) {
				// Add monument to myNfts
				myNfts.push({ id: monumentId, title: monument.title, items: existingItems });
			} else {
				// Get existing items as monument already exists in myNfts
				existingItems = monumentExistingInMyNfts.items;
			}

			// Add PreOrder dac (item) to monument items in myNfts
			const dac = monument.items.find((d) => d.id === Number(preOrder.id));
			if (dac) {
				const newItem = {
					id: preOrder.id,
					productType: preOrder.productType,
					tokenId: preOrder.tokenId,
					contractAddr: preOrder.contractAddress,
					addrOwner: preOrder.owner,
					logo: dac.logo,
					number: dac.number,
					year: dac.year,
					author: dac.author,
					description: dac.description,
					descriptionEn: dac.descriptionEn,
					short: dac.short,
					shortEn: dac.shortEn,
					name: dac.name,
					imgUrl: dac.imgUrl,
					poster: dac.poster,
					assetUrl: dac.assetUrl,
					qte: dac.qte,
					available: dac.available,
					price: {
						euro: dac.price.euro,
						dollars: dac.price.dollars,
						matic: dac.price.matic,
					},
				};
				existingItems.push(newItem);
			}

			myNfts = myNfts.map((obj) => {
				if (obj.id === monumentId) {
					return { ...obj, items: existingItems };
				}
				return obj;
			});
		}
	});
	return { myNfts, myBoxes };
}

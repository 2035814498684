import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { signIn } from 'next-auth/react';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';
import { Stack, Button, Box, Typography, IconButton } from '@mui/material';

import PopIn from '../../components/PopIn';
import PopInLanding from './PopInLanding';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import style from './Landing.style';

import posterTeaser from './img/player.jpg';
import PlayIcon from './img/play.svg';
import ArcLogo from './img/ARC.png';
import Logo101 from './img/logo101square.jpg';
import logo from '../../asset/images/logos/logo-white.png';
import { colorOr } from '../../themes/theme';
import Newsletter from '../Newsletter';
import { Spacer, VStack } from '@chakra-ui/react';

export default function Landing({ dropUntilDatetime }: { dropUntilDatetime: string }) {
	const { t } = useTranslation(['home', 'header', 'landing']);
	const [showLogin, setShowLogin] = useState<boolean>(false);
	const [showRegistration, setShowRegistration] = useState<boolean>(false);
	const [showVideo, setShowVideo] = useState<boolean>(false);
	const [showWorldPremiere, setShowWorldPremiere] = useState<boolean>(true);

	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	useEffect(() => {
		const target = new Date(dropUntilDatetime);

		const interval = setInterval(() => {
			const now = new Date();
			const difference = target.getTime() - now.getTime();

			const d = Math.floor(difference / (1000 * 60 * 60 * 24));
			setDays(d);

			const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			setHours(h);

			const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
			setMinutes(m);

			const s = Math.floor((difference % (1000 * 60)) / 1000);
			setSeconds(s);

			if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
				setShowWorldPremiere(false);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Stack sx={style.backgroundContent}>
			<Stack direction="column" height="auto" minHeight={'100vh'}>
				<Stack direction="column">
					{/* Content Box Logo VIP */}
					<Box sx={style.logo}>
						<Image alt="logo" src={logo.src} width={219} height={45} />
						{/*<Typography textAlign="end" style={style.typo}>
							{t('vipEarlyAccess', { ns: 'landing' })}
						</Typography>*/}
					</Box>
				</Stack>

				<Stack>
					<Typography sx={style.welcomeMessageContent} variant={'h1'}>
						{t('welcome', { ns: 'landing' })} <span style={{ whiteSpace: 'nowrap' }}>THE---101EXPERIENCE</span>
					</Typography>
				</Stack>
				<Stack sx={style.welcomeSubtitleMessage}>
					<Typography variant={'h2'}>{t('welcomeSubtitle', { ns: 'landing' })}</Typography>
				</Stack>

				{/* Content Video */}
				<Stack sx={style.videoContent}>
					<Image
						width={0}
						height={0}
						priority={false}
						style={style.video}
						src={posterTeaser}
						alt="Teaser video The 101 Experience"
						onClick={() => {
							setShowVideo(true);
						}}
					/>

					{/*<video
						//ref={vidRef as HTMLVideoElement}
						ref={(rv) => {
							videoRefs[0] = rv as HTMLVideoElement;
							setVideoRefs(videoRefs);
						}}
						onClick={() => {
							if (videoRefs[0]) {
								if (videoRefs[0].paused) {
									void videoRefs[0].play();
								} else {
									videoRefs[0].pause();
								}
							}
						}}
						poster={terreGif.src}
						style={{ border: `2px solid ${colorOr}`, cursor: 'pointer' }}
					>
						<source src={isMobile ? '/teasermobile.mp4' : '/teaser.mp4'} type="video/mp4" />
					</video>
				*/}
				</Stack>
				{/* Content Button Play Video */}
				<Stack sx={style.buttonVideoContent}>
					<IconButton
						onClick={() => {
							setShowVideo(true);
						}}
					>
						<Image width={0} height={0} src={PlayIcon} alt="Play" style={{ marginRight: '8px' }} />
						{t('showVideo', { ns: 'landing' })}
					</IconButton>
				</Stack>

				{/* Content Message */}
				<Stack sx={style.typoMessageContent} paddingTop={2}>
					<Typography sx={style.typoMessage} textTransform={'uppercase'}>
						{t('message1', { ns: 'landing' })}
					</Typography>
					<Typography sx={style.typoMessage} textTransform={'uppercase'} paddingTop={1}>
						{t('message2', { ns: 'landing' })}
					</Typography>
				</Stack>

				{/* Content Button */}
				<Stack sx={style.buttonContent}>
					<Stack className="flex-item" />

					{/* Content Button Connection */}
					<VStack className="flex-item-middle" sx={style.buttonConnectionContent}>
						<Button
							onClick={(e) => {
								e.preventDefault();
								signIn();
							}}
							variant="contained"
							sx={{ ...style.iconButton, width: '300px' }}
						>
							{process.env.NEXT_PUBLIC_SALE_IS_ACTIVE === 'false'
								? t('enterExperienceCollection', { ns: 'landing' })
								: t('enterExperience', { ns: 'landing' })}
						</Button>
						{process.env.NEXT_PUBLIC_SALE_IS_ACTIVE === 'false' && <Newsletter></Newsletter>}
						{/*<Button onClick={() => setShowLogin(true)} variant="outlined" sx={{ ...style.iconButton, width: '240px' }}>
							{t('signIn', { ns: 'navigation' })}
						</Button>
						<Button
							variant="contained"
							onClick={() => setShowRegistration(true)}
							sx={{ ...style.iconButton, width: '240px' }}
						>
							{t('askAccess', { ns: 'landing' })}
						</Button>*/}
					</VStack>
					<Stack className="flex-item" />

					{/* Content Button Info Lang */}
					{/*<Stack className="flex-item" sx={style.buttonInfoLangContent}>
						<IconButton sx={style.iconButton}>
							<Image width={0} height={0} src={InfoIcon} alt="Play" style={{ marginRight: '8px' }} />
							{t('info', { ns: 'landing' })}
						</IconButton>
						<IconButton sx={style.iconButton}>
							<Image width={0} height={0} src={TerreIcon} alt="Terre" style={{ marginRight: '8px' }} />
							{t('lang', { ns: 'landing' })}
						</IconButton>
					</Stack>*/}
				</Stack>
			</Stack>

			<PopInLanding onClose={() => setShowWorldPremiere(false)} open={showWorldPremiere}>
				<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
					<Typography sx={style.welcomeMessageContent} variant={'h1'}>
						{t('World Premiere', { ns: 'landing' })}
					</Typography>
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
						<Image src={Logo101} alt={'Logo 101'} width={isMobile ? 100 : 150} height={0} />
						<Typography variant={'h1'}>X</Typography>
						<Image src={ArcLogo} alt={'Logo Arc'} width={isMobile ? 100 : 150} height={0} />
					</Stack>
					<Typography variant={'h4'}>{t('Drop until', { ns: 'landing' })}</Typography>
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
						<div className="timer-wrapper">
							<div className="timer-inner" style={isMobile ? { fontSize: '2.7rem' } : { fontSize: '4rem' }}>
								<div className="timer-segment">
									<span className="time">{days}</span>
									<span className="label" style={isMobile ? { fontSize: '0.8rem' } : { fontSize: '1rem' }}>
										{t('Days', { ns: 'landing' })}
									</span>
								</div>
								<span className="divider">:</span>
								<div className="timer-segment">
									<span className="time">{hours}</span>
									<span className="label" style={isMobile ? { fontSize: '0.8rem' } : { fontSize: '1rem' }}>
										{t('Hours', { ns: 'landing' })}
									</span>
								</div>
								<span className="divider">:</span>
								<div className="timer-segment">
									<span className="time">{minutes}</span>
									<span className="label" style={isMobile ? { fontSize: '0.8rem' } : { fontSize: '1rem' }}>
										{t('Minutes', { ns: 'landing' })}
									</span>
								</div>
								<span className="divider">:</span>
								<div className="timer-segment">
									<span className="time">{seconds}</span>
									<span className="label" style={isMobile ? { fontSize: '0.8rem' } : { fontSize: '1rem' }}>
										{t('Seconds', { ns: 'landing' })}
									</span>
								</div>
							</div>
						</div>
					</Stack>
					<Stack direction="row" justifyContent="center" alignItems="center">
						<Button
							onClick={(e) => {
								e.preventDefault();
								signIn();
							}}
							variant="outlined"
							sx={{
								...style.iconButton,
								width: '220px',
								'&:hover': {
									borderColor: colorOr,
									backgroundColor: 'white',
									color: '#020202',
									fontWeight: 'bold',
								},
							}}
						>
							{t('signIn', { ns: 'navigation' })}
						</Button>
					</Stack>
				</Stack>
			</PopInLanding>

			<PopIn title="" onClose={() => setShowLogin(false)} open={showLogin}>
				<LoginForm
					signInClick={() => {
						setShowLogin(false);
						setShowRegistration(true);
					}}
				/>
			</PopIn>
			<PopIn title="" onClose={() => setShowRegistration(false)} open={showRegistration}>
				<RegisterForm
					logInClick={() => {
						setShowRegistration(false);
						setShowLogin(true);
					}}
				/>
			</PopIn>
			<PopIn title="" onClose={() => setShowVideo(false)} open={showVideo}>
				<Stack
					sx={{
						marginTop: '3vh',
						width: '100%',
						maxWidth: '1280px',
						height: 'calc( 100vh - 80px )',
						justifyContent: 'center',
					}}
				>
					<video
						style={{
							paddingBottom: '20vh',
						}}
						controls
						autoPlay={true}
					>
						<source src={'/teaser.mp4'} type="video/mp4" />
					</video>
				</Stack>
			</PopIn>
		</Stack>
	);
}

import React from 'react';
import Image from 'next/image';
// import { isMobileOnly } from 'react-device-detect';
import { Stack, Typography } from '@mui/material';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './Slider.style';
// import Bar from '../Bar';

interface SliderProps {
	items: Item[];
	title: string;
	onSelect: (item: Item) => void;
}

function Slider({ items, title, onSelect }: SliderProps) {
	return (
		<Stack sx={{ margin: 'auto 15px 32px' }}>
			<Typography
				sx={{
					textTransform: 'uppercase',
					marginBottom: '10px',
					fontSize: '22px',
				}}
			>
				{items.length > 0 && title}
			</Typography>
			<Stack sx={styles.sliderContainer}>
				<Swiper
					className="slider"
					style={{
						position: 'relative',
						display: 'block',
						marginTop: '50px',
						width: 'auto',
					}}
					modules={[Navigation, A11y]}
					slidesPerView="auto"
					spaceBetween={10}
					navigation
					scrollbar={{ draggable: true }}
				>
					{items?.map((item, index) => (
						<SwiperSlide key={index}>
							<Stack
								key={index}
								/*sx={
									isMobileOnly
										? { position: 'relative', marginRight: '5px' }
										: { position: 'relative', marginRight: '15px' }
								}*/
								sx={{ position: 'relative', marginRight: '15px' }}
							>
								<Image
									key={index}
									className="item"
									data-value={item.name}
									/*width={isMobileOnly ? '110' : '366'}
									height={isMobileOnly ? '196' : '650'}*/
									width={'366'}
									height={'650'}
									priority={true}
									src={!item.imgUrl.endsWith('.mp4') ? (item.imgUrl as string) : (item.poster as string)}
									alt={item.name}
									style={{ ...styles.sliderImage }}
									onClick={() => {
										if (onSelect) {
											onSelect(item);
										}
									}}
								/>
								{/*{<Bar item={item} info={false} />}*/}
							</Stack>
						</SwiperSlide>
					))}
				</Swiper>
			</Stack>
		</Stack>
	);
}
export default Slider;

import React from 'react';
import { useTranslation } from 'next-i18next';
import { CircularProgress } from '@mui/material';

import { getPricesAndSupply } from '../../utils/preorders';
import { ProductType } from '../../utils/types/product-type';
import { fontSize } from '@mui/system';

interface PricesTag {
	productType: ProductType;
}

export default function PricesTag({ productType }: PricesTag) {
	const { t } = useTranslation(['home', 'header']);
	const { currentSupply, saleIsActive, prices, isLoading } = getPricesAndSupply();
	return (
		<>
			{isLoading ? (
				<>
					<CircularProgress size={'1rem'} sx={{ marginRight: 1 }} />
					{/*{t('loadingPrices')}*/}
				</>
			) : (
				<>
					<b>
						{prices?.[ProductType[productType]]?.eur}
						<small style={{ fontSize: '0.9em' }}> €</small>
					</b>{' '}
					/ {Math.round(prices?.[ProductType[productType]]?.matic)} <small style={{ fontSize: '0.7em' }}>MATIC</small>
				</>
			)}
		</>
	);
}

import { Stack, Button, Typography } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { signIn } from 'next-auth/react';
import * as Yup from 'yup';
import { useSearchParams, useRouter } from 'next/navigation';
import FormBox from '../../../components/FormBox';
import TextField from '../../../components/TextField';
import ErrorField from '../../ErrorField';

type Props = {
	signInClick: () => void;
};
// See: https://cloudcoders.xyz/blog/nextauth-credentials-provider-with-external-api-and-login-page/
export default function LoginForm({ signInClick }: Props) {
	const { t } = useTranslation(['landing']);
	const router = useRouter();
	const searchParams = useSearchParams();
	const callbackUrl = searchParams.get('callbackUrl') || `${window.location.origin}`;
	const [errorLogin, setErrorLogin] = useState<boolean>(false);

	return (
		<FormBox>
			<Stack direction="row" spacing={2} justifyContent="center" mb={4}>
				<Button onClick={signInClick} sx={{ color: 'white' }}>
					{t('signinButton')}
				</Button>
				<Button sx={{ cursor: 'default' }} variant="outlined">
					{t('loginButton')}
				</Button>
			</Stack>
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={Yup.object({
					email: Yup.string().email('Invalid email address').required('Please enter your email'),
					password: Yup.string().required('Please enter your password'),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					const res = await signIn('credentials', {
						redirect: false,
						email: values.email,
						password: values.password,
						callbackUrl: callbackUrl,
					});
					if (res?.error) {
						// setError(res.error);
						setErrorLogin(true);
					} else {
						setErrorLogin(false);
						console.log('LOGIN SUCCESS');
						if (res?.url) router.push(res.url);
						// window.location.reload();
					}
					setSubmitting(false);
				}}
			>
				<Form>
					<TextField label={t('email')} name="email" />
					<ErrorField name="email" />
					<TextField label={t('password')} name="password" type="password" />
					<ErrorField name="password" />
					{errorLogin && <Typography color="red">Error</Typography>}
					<Stack direction="row" justifyContent="center" paddingTop={4}>
						<Button sx={{ width: '200px' }} variant="contained" type="submit">
							{t('enter')}
						</Button>
					</Stack>
				</Form>
			</Formik>
		</FormBox>
	);
}

// Landing.style.tsx

import { colorOr } from '../../themes/theme';

const styles = {
	backgroundContent: {
		//maxWidth: '990px',
		backgroundColor: '#111111',
		backgroundImage: 'url("/images/backgroundstars.png")',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		paddingBottom: '50px',
	},
	logo: {
		width: '250px',
		textAlign: 'end',
		marginTop: '20px',
	},
	iconButton: {
		margin: '10px 15px',
	},
	videoContent: {
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 20px 0 20px',
	},
	video: {
		maxWidth: '1280px',
		marginRight: '8px',
		padding: '0 3%',
		height: 'auto',
		width: '100%',
	},
	buttonVideoContent: {
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginBottom: 'auto',
	},
	welcomeMessageContent: {
		justifyContent: 'center',
		alignItems: 'center',
		//flexWrap: 'wrap',
		margin: 'auto',
		//width: '100%',
		textAlign: 'center',
		//minWidth: '320px',
		//flex: '1',
		fontSize: '1.9rem',
		color: colorOr,
		paddingTop: '20px',
	},
	name101: {
		paddingTop: '2px',
		whiteSpace: 'nowrap',
	},
	welcomeSubtitleMessage: {
		fontSize: '1.5rem',
		textAlign: 'center',
		paddingTop: '15px',
		paddingBottom: '50px',
		marginLeft: '10px',
		marginRight: '10px',
	},
	typo: {
		backgroundColor: colorOr,
		margin: '10px 0',
		padding: '6px',
	},
	typoMessageContent: {
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		margin: '5px',
		marginBottom: '10px',
	},
	typoMessage: {
		margin: 'auto',
		textAlign: 'center',
		flex: '1',
	},
	buttonContent: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		margin: '5px 5px 3%',
	},
	buttonConnectionContent: {
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	buttonInfoLangContent: {
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
};

export default styles;

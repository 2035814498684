import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import style from './Why.style';
import Image from 'next/image';
import AviatorRose from '../img/101_rose.svg';
interface WhyProps {}

export default function Why({}: WhyProps) {
	const { t } = useTranslation(['home', 'header']);

	return (
		<Box sx={style.backgroundContent}>
			<Stack sx={style.blockContent}>
				<Stack sx={style.headerContent}>
					<Typography sx={style.headerTypo} variant="h1" marginTop={2}>
						{t('whyBox.headerTitle')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={4}>
						{t('whyBox.arg1')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h3" marginTop={1}>
						{t('whyBox.arg1desc')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={2}>
						{t('whyBox.arg2')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h3" marginTop={1}>
						{t('whyBox.arg2desc')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={2}>
						{t('whyBox.arg3')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h3" marginTop={1}>
						{t('whyBox.arg3desc1')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h3" marginTop={1}>
						{t('whyBox.arg3desc2')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={2}>
						{t('whyBox.arg4')}{' '}
						<Image
							style={{ marginLeft: '15px', marginTop: '10px' }}
							src={AviatorRose}
							alt={'Logo 101 special'}
							width={193}
							height={17}
						></Image>
					</Typography>

					<Typography sx={style.headerTypoSub} variant="h2" marginTop={2}>
						{t('whyBox.arg5')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h3" marginTop={1} marginBottom={3}>
						{t('whyBox.arg5desc')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1} color={'primary.main'}>
						{t('whyBox.arg6')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h3" marginTop={1} marginBottom={3}>
						{t('whyBox.arg6desc')}
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
}

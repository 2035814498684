import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import style from './What.style';
interface WhyProps {}

export default function What({}: WhyProps) {
	const { t } = useTranslation(['home', 'header']);

	return (
		<Box sx={style.backgroundContent}>
			<Stack sx={style.blockContent}>
				<Stack sx={style.headerContent}>
					<Typography sx={style.headerTypo} variant="h1">
						{t('whatBox.headerTitle')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={3}>
						{t('whatBox.arg1')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatBox.arg2')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatBox.arg3')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatBox.arg4')}
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
}

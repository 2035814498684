// What.style.tsx

const styles = {
	backgroundContent: {
		//maxWidth: '990px',
		backgroundColor: '#bcb4ac',
		//backgroundImage: 'url("/images/background.png")',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		paddingBottom: '40px',
	},
	blockContent: {
		direction: 'column',
		flexDirection: 'column',
		flex: 1,
		maxWidth: '1280px',
		//maxWidth: '990px',
		margin: 'auto',
	},
	headerContent: {
		backgroundColor: 'none',
		justifyContent: 'center',
		//alignItems: 'center',
		spacing: 1,
		padding: '41px 39px',
		flexDirection: 'column',
		textAlign: 'left',
	},

	headerTypo: {
		textTransform: 'uppercase',
		fontSize: '26px',
		fontWeight: 'normal',
		color: 'primary.dark',
		textAlign: 'center',
	},
	headerTypoSub: {
		fontWeight: 'normal',
		color: 'primary.dark',
	},
};

export default styles;

import { Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'next-i18next';
import FormBox from '../../../components/FormBox';
import payStyles from './PayAccept.style';

interface PayAcceptProps {
	onBack: () => void;
}

export default function PayAccept({ onBack }: PayAcceptProps) {
	const { t } = useTranslation();
	const payAcceptMessage = t('MSG_PAY_ACCEPT');

	return (
		<FormBox>
			<Stack sx={payStyles.contained}>
				<CheckCircleOutlineIcon sx={payStyles.iconRef} />
				<Typography
					sx={payStyles.typo}
					variant="body1"
					component="div"
					dangerouslySetInnerHTML={{ __html: payAcceptMessage }}
				/>
				<Typography sx={payStyles.back} onClick={onBack}>
					{t('BACK_HOME')}
				</Typography>
			</Stack>
		</FormBox>
	);
}

import React from 'react';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useSession } from 'next-auth/react';

import Landing from '../components/Landing';
import Home from '../components/Home';
import { CircularProgress, Stack } from '@mui/material';

type Props = {
	// Add custom props here
};
export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => ({
	props: {
		saleIsActive: true,
		dropUntil: process.env.DROP_UNTIL,

		...(await serverSideTranslations(locale ?? 'en', [
			'header',
			'navigation',
			'landing',
			'home',
			'payment',
			'profile',
		])),
	},
});
export default function IndexPage({ dropUntil }: { dropUntil: string }) {
	const { data: session, status } = useSession();
	if (status === 'loading') {
		return (
			<Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 1, height: '100vh' }}>
				<CircularProgress size={'3rem'} />
			</Stack>
		);
	}
	if (status === 'authenticated' && session?.user) {
		return <Home />;
	}
	return <Landing dropUntilDatetime={dropUntil} />;
}

// VipBox.style.tsx

import { colorOr } from '../../../themes/theme';

const styles = {
	backgroundContent: {
		//maxWidth: '990px',
		//backgroundColor: '#111111',
		//backgroundImage: 'url("/images/background.png")',
		//backgroundSize: 'cover',
		//backgroundPosition: 'center',
		paddingBottom: '50px',
	},
	blockContent: {
		direction: 'column',
		flexDirection: 'column',
		flex: 1,
		maxWidth: '1280px',
		//maxWidth: '990px',
		margin: 'auto',
	},
	headerContent: {
		backgroundColor: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		spacing: 1,
		padding: '41px 39px',
		flexDirection: 'column',
		textAlign: 'center',
	},
	vipContentText: {
		flex: 3,
		flexDirection: 'row',
		minWidth: '292px',
	},
	iconButton: {
		left: '5px',
		position: 'absolute',
		height: '25px',
	},
	buttonCollection: {
		marginLeft: '20px',
		width: '250px',
	},
	itemContent: {
		overflowX: 'hidden',
		width: 'calc( 100vw - 32px )',
		margin: 'auto',
	},
	headerTypo: {
		fontSize: '2.4rem',
		fontWeight: 'normal',
		color: 'primary.light',
	},
	headerTypoSub: {
		fontSize: '1.5rem',
		fontWeight: 'normal',
	},
	vipContent: {
		m: 2,
		borderRadius: '7px',
		backgroundColor: '#141617',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: 'primary.main',
		padding: '30px',
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	vip: {
		color: colorOr,
		fontSize: '22px',
		fontWeight: 400,
	},
	vipMessage: {
		marginTop: '10px',
		fontSize: '15',
		fontWeight: 400,
		margin: '15px 0',
	},
	iconCard: {
		width: '65px',
		marginRight: '10px',
	},
	choiceBoxContent: { flexDirection: 'row', flexWrap: 'wrap' },
	choiceContent: {
		flex: 1,
		borderRadius: '25px',
		margin: '15px',
		padding: '40px',
		backgroundColor: '#141617',
		//background: 'rgb(2,2,2)',
		filter: 'drop-shadow(10px 20px 20px #020202)',
		background: 'linear-gradient(0deg, rgba(2,2,2,1) 0%, rgba(24,22,25,1) 80%, rgba(43,38,45,1) 100%)',
		flexWrap: 'wrap',
		minWidth: '300px',
	},
	cardContent: { flex: 0 },
	typoContent: { flex: 3 },
	buttonContent: { flex: 1, margin: 'auto' },
	vipButton: { lineHeight: '15px', minWidth: '250px' },

	silverGradient: {
		background: 'linear-gradient(to left, #CFCFCF 0%, #656564 100%)',
		textShadow: '#CFCFCF 1px 0 30px',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		backgroundClip: 'text',
	},
	goldGradient: {
		background: 'linear-gradient(to left, #deca9e 0%, #ad8a56 100%)',
		textShadow: '#deca9e 1px 0 30px',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		backgroundClip: 'text',
	},
	platinumGradient: {
		background: 'linear-gradient(to left, #ebf8f8 0%, #c0e9e9 100%)',
		textShadow: '#ebf8f8 1px 0 30px',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		backgroundClip: 'text',
	},
};

export default styles;

import React, { useState, FC } from 'react';
import { useTranslation } from 'next-i18next';
import { Typography, TextField } from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import { formatAmountForDisplay, formatAmountFromStripe } from '../../utils/stripe-helpers';
import ButtonStripePayment from '../ButtonStripePayment';
import { CurrencyType } from '../../utils/types/currency-type';
import { fetchPostJSON } from '../../utils/api-helpers';

const ElementsForm: FC<{
	paymentIntent: PaymentIntent;
	email: string;
}> = ({ paymentIntent, email }) => {
	const { t } = useTranslation(['payment', 'home']);
	const [input, setInput] = useState({
		amount: formatAmountFromStripe(paymentIntent?.amount, paymentIntent?.currency as CurrencyType),
		cardholderName: '',
	});
	const [paymentType, setPaymentType] = useState('');
	const [payment, setPayment] = useState({ status: 'initial' });
	const stripe = useStripe();
	const elements = useElements();

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
		setInput({
			...input,
			[e.currentTarget.name]: e.currentTarget.value,
		});

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();
		// Abort if form isn't valid
		if (!e.currentTarget.reportValidity()) return;
		if (!elements) return;
		setPayment({ status: 'processing' });

		// Create a PaymentIntent with the specified amount.
		const response = await fetchPostJSON('/api/payment_intents', {
			amount: input.amount,
			currency: paymentIntent?.currency,
			payment_intent_id: paymentIntent?.id,
		});
		setPayment(response);

		if (response.statusCode === 500) {
			setPayment({ status: 'error' });
			return;
		}

		const { error } = await stripe!.confirmPayment({
			elements,
			confirmParams: {
				return_url: process.env.NEXT_PUBLIC_STRIPE_RETURN_URL as string,
				payment_method_data: {
					billing_details: {
						name: input.cardholderName,
						email: email,
					},
				},
			},
		});

		if (error) {
			console.error(error);
			setPayment({ status: 'error' });
		} else if (paymentIntent) {
			setPayment(paymentIntent);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				{paymentType === 'card' ? (
					<fieldset className="elements-style" style={{ marginBottom: '25px', border: 'none', padding: '0' }}>
						<legend style={{ marginBottom: '10px', fontSize: '1.1em' }}>
							{t('cardholder-name', { ns: 'payment' })}
						</legend>
						<TextField
							size="small"
							id="outlined-basic"
							name="cardholderName"
							required
							fullWidth
							variant="outlined"
							color="primary"
							focused
							inputProps={{
								style: { color: 'white', borderColor: 'white' },
							}}
						/>
					</fieldset>
				) : null}
				<fieldset className="elements-style" style={{ marginBottom: '25px', border: 'none', padding: '0' }}>
					<legend style={{ marginBottom: '10px', fontSize: '1.1em' }}>{t('type-of-payment', { ns: 'payment' })}</legend>

					<div className="FormRow elements-style">
						<PaymentElement
							options={{ layout: { type: 'tabs' } }}
							onChange={(e) => {
								setPaymentType(e.value.type);
							}}
						/>
					</div>
				</fieldset>

				{payment?.status === 'error' ? (
					<Typography color="primary.light" textAlign="center" paddingTop={3}>
						{t('payment-input-error', { ns: 'payment' })}
					</Typography>
				) : null}

				<ButtonStripePayment
					isDisabled={!['initial', 'succeeded', 'error'].includes(payment.status) || !stripe}
					label={`${t('pay', { ns: 'payment' })} ${formatAmountForDisplay(
						formatAmountFromStripe(paymentIntent?.amount, paymentIntent?.currency as CurrencyType),
						paymentIntent?.currency as CurrencyType
					)}`}
				/>
			</form>
		</>
	);
};

export default ElementsForm;

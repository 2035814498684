import { colorOr } from '../../../themes/theme';

const buyStyles = {
	imgContainer: {
		maxHeight: '220px',
		maxWidth: '300px',
		// width: 'auto',
		margin: 'auto',
		// border: `1px solid ${colorOr}`,
		style: 'filter: drop-shadow(32px 32px 16px)',
	},
	priceText: {
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		margin: '13px',
		fontSize: '23px',
		textAlign: 'center',
		//color: colorOr,
	},
	currencyButtonContainer: {
		margin: 'auto',
		marginTop: '15px',
	},
	currencyButton: {
		height: '40px',
		width: '40px',
		lineHeight: '40px',
		fontWeight: '500',
		color: 'white',
		fontSize: '22px',
		textAlign: 'center',
		borderRadius: '50%',
		margin: '0px 10px 0px 0',
	},
	euroButton: {
		background: '#387ADD',
	},
	dollarsButton: {
		background: '#6C9765',
	},
	maticButtonContainer: {
		margin: 'auto',
		marginTop: '15px',
		//marginLeft: '27px',
	},
	maticIcon: {
		height: 60,
		width: 60,
		top: '-12px',
		position: 'relative',
		'& svg': {
			fill: '#8247E5',
		},
		marginLeft: '-10px',
	},
	cancelButtonContainer: {
		margin: '27px auto 0',
	},
	cancelButton: {
		width: '240px',
		marginLeft: '60px',
		backgroundColor: 'white',
	},
	termsContainer: {
		margin: '15px auto',
		marginRight: '29px',
		marginLeft: '15px',
	},
	termsText: {
		width: '240px',
		textAlign: 'start',
		textDecoration: 'underline',
		marginLeft: '16px',
	},
};

export default buyStyles;

import * as React from 'react';
import { useEffect } from 'react';
import Router from 'next/router';
import { useSession } from 'next-auth/react';
import { formatEther } from 'viem';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { fetchPostJSON } from '../utils/api-helpers';
import FormBox from './FormBox';
import { ProductType } from '../utils/types/product-type';
import { chainIdentifier, preOrderContractAddress } from '../utils/constants';
import buyStyles from './Home/Buy/Buy.style';
import PricesTag from './PricesTag/PricesTag';

interface MintParams {
	item: Item;
	enabled: boolean;
	recipient: string;
	orderId: number;
	productType: ProductType;
	priceInWei: bigint;
}

export function MintNFTPreOrder({ item, enabled, recipient, orderId, productType, priceInWei }: MintParams) {
	const { data: session } = useSession();

	const {
		config,
		error: prepareError,
		isError: isPrepareError,
	} = usePrepareContractWrite({
		abi: [
			{
				inputs: [
					{
						internalType: 'address',
						name: 'to',
						type: 'address',
					},
					{
						internalType: 'uint256',
						name: 'id',
						type: 'uint256',
					},
					{
						internalType: 'enum IEarly101.ProductType',
						name: 'productType',
						type: 'uint8',
					},
				],
				name: 'safeMint',
				outputs: [
					{
						internalType: 'uint256',
						name: '',
						type: 'uint256',
					},
				],
				stateMutability: 'payable',
				type: 'function',
			},
		],
		address: preOrderContractAddress as `0x${string}`,
		args: [recipient, orderId, productType],
		chainId: chainIdentifier,
		enabled: enabled,
		functionName: 'safeMint',
		value: priceInWei,
	});
	const { data, error, isError, write } = useContractWrite(config);
	const {
		data: receipt,
		error: transactionError,
		isError: isTransactionError,
		isLoading,
		isSuccess,
	} = useWaitForTransaction({
		hash: data?.hash,
	});

	useEffect(() => {
		if (isSuccess) {
			const addCryptoTransaction = async () => {
				// Update or create the transaction Order
				const transactionId = await fetchPostJSON('/api/add_transaction', {
					recipient: recipient,
					email: session?.user?.email,
					masterId: orderId,
					quantity: 1,
					priceInEuro: null,
					priceInMatic: formatEther(priceInWei),
					stripeTransactionId: null,
					status: 'SUCCESS',
					transactionHash: data?.hash,
					transactionReceipt: receipt,
					metadata: {
						orderId: orderId,
						quantity: 1,
						productType: ProductType[productType],
						tokenId: BigInt(receipt?.logs[1].topics[3] ?? 0).toString(),
						userName: session?.user?.name,
						userLanguage: 'fr-FR',
					},
				});
				return transactionId;
			};
			addCryptoTransaction()
				.then((transactionId) => {
					console.debug(`Transaction ${transactionId} added`);
					// TODO maybe send email?
					// Call sweetalert2 to show success
					Router.push(
						`/congrats?transaction_id=${transactionId}&transaction_hash=${data?.hash}&redirect_status=succeeded`
					);
				})
				.catch((error) => {
					console.error('Error while adding transaction', error);
					// Call sweetalert2 to show error
				});
			return () => {
				// this now gets called when the component unmounts
			};
		}
	}, [isSuccess]); // useEffect will run on each component render

	function buy() {
		if (enabled && write) {
			write();
		}
	}

	return (
		<FormBox>
			<Typography variant="h1" sx={buyStyles.priceText}>{`${item.name}`}</Typography>
			<img src={item.imgUrl} alt={item.name} style={buyStyles.imgContainer} />
			<Typography variant="h1" sx={buyStyles.priceText}>
				<PricesTag productType={item.productType} />
			</Typography>
			<Box textAlign="center">
				<Button
					variant="contained"
					sx={{ width: '240px' }}
					onClick={() => buy()}
					disabled={!enabled || !write || isLoading}
				>
					{isLoading ? 'Minting...' : 'Mint with Wallet'}
				</Button>
			</Box>
			{/*{isSuccess && (
				<div>
					Successfully minted your NFT!
					<div>
						<a href={`${process.env.NEXT_PUBLIC_POLYGON_SCAN_URL}/tx/${data?.hash}`}>View on Polygonscan</a>
					</div>
				</div>
			)}*/}
			{isPrepareError || isError || isTransactionError ? (
				<Box marginTop={2}>
					{isPrepareError && (
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="prepareerror-content"
								id="prepareerror-header"
							>
								<Typography color={'black'}>Prepare Error</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h6" color={'black'}>
									{prepareError?.message}
								</Typography>
							</AccordionDetails>
						</Accordion>
					)}
					{isError && (
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="error-content" id="error-header">
								<Typography color={'black'}>Error</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="h5" color={'black'}>
									{error?.message}
								</Typography>
							</AccordionDetails>
						</Accordion>
					)}
					{isTransactionError && (
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="transactionerror-content"
								id="transactionerror-header"
							>
								<Typography color={'black'}>Transaction Error</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography noWrap variant="h4" color={'black'}>
									{transactionError?.message}
								</Typography>
							</AccordionDetails>
						</Accordion>
					)}
				</Box>
			) : null}
		</FormBox>
	);
}

// PayRef.style.tsx

const payStyles = {
	contained: {
		height: '60vh',
	},
	iconRef: {
		margin: 'auto',
		marginTop: '50px',
		fontSize: '80px',
		color: '#f00',
		marginBottom: 0,
	},
	typo: {
		margin: 'auto',
		textAlign: 'center',
	},
	back: {
		textDecoration: 'underline',
		fontSize: 'small',
		textAlign: 'center',
		marginBottom: '100px',
		color: '#848789',
	},
};

export default payStyles;

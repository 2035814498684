// PayAccept.style.tsx

import { maxWidth } from '@mui/system';

const payStyles = {
	contained: {
		height: '60vh',
	},
	iconRef: {
		margin: 'auto',
		marginTop: '50px',
		fontSize: '80px',
		color: '#0daf00',
		marginBottom: 0,
	},
	iconError: {
		margin: 'auto',
		marginTop: '50px',
		fontSize: '80px',
		color: 'tomato',
		marginBottom: 0,
	},
	typo: {
		margin: 'auto',
		textAlign: 'center',
	},
	back: {
		cursor: 'pointer',
		textDecoration: 'underline',
		fontSize: '1rem',
		textAlign: 'center',
		marginBottom: '25px',
		color: '#848789',
	},
};

export default payStyles;

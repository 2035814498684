import { Button, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';

interface ButtonStripePaymentProps {
	label: string;
	isDisabled?: boolean;
	onClick?: () => void;
}

const ButtonStripePayment = ({ label, isDisabled, onClick }: ButtonStripePaymentProps) => {
	const [loadingPayment, setLoadingPayment] = useState(false);

	const handleClick = () => {
		setLoadingPayment(true);
		if (onClick) {
			onClick();
		}
	};

	useEffect(() => {
		if (isDisabled) {
			setLoadingPayment(true);
		} else {
			setLoadingPayment(false);
		}
	}, [isDisabled]);

	return (
		<Button
			variant="contained"
			sx={{
				width: '100%',
				marginTop: '15px',
				fontSize: '1.1rem',
				fontWeight: 700,
			}}
			onClick={handleClick}
			disabled={isDisabled}
			type="submit"
		>
			{loadingPayment && <CircularProgress size={'1.1rem'} style={{ marginRight: '8px' }} />}
			{label}
		</Button>
	);
};

export default ButtonStripePayment;

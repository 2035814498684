// Newsletter.style.tsx

import { colorOr } from '../../themes/theme';

const newsLetterStyles = {
	contained: {
		textAlign: 'center',
		borderRadius: '25px',
		backgroundColor: '#111111',
		padding: '20px 0 20px 0',
		borderColor: colorOr,
		borderStyle: 'solid',
		marginTop: '40px',
		marginBottom: '40px',
		marginRight: '12px',
		marginLeft: '12px',
	},
	stack: {
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 40px 0 40px',
	},
	text: {
		justifyContent: 'center',
		alignItems: 'center',
		margin: 'auto',
		width: '100%',
		textAlign: 'center',
		fontSize: '1.1rem',
		color: colorOr,
	},
	inputEmail: {
		color: colorOr,
		borderColor: colorOr,
		borderStyle: 'solid',
		borderRadius: '25px',
	},
};

export default newsLetterStyles;

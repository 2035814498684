export enum ProductType {
	DAC = 0,
	SILVER = 1,
	GOLD = 2,
	PLATINUM = 3,
}

export enum OldProductType {
	Master = 'master',
	MasterBox = 'masterBox',
	Collector = 'collector',
	CryptoMon = 'cryptoMon',
	Event = 'event',
	EverRoseEvent = 'everRoseEvent',
}

import React from 'react';
import Image from 'next/image';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import style from './VipBox.style';
import IconGoldCard from './img/goldCard.svg';
import IconSilverCard from './img/silverCard.svg';
import IconPlatinumCard from './img/platinumCard.svg';
import PricesTag from '../../PricesTag/PricesTag';
import { ProductType } from '../../../utils/types/product-type';
import { GOLD_BOX, PLATINUM_BOX, SILVER_BOX } from '../../../asset/monuments';
import { colorOr } from '../../../themes/theme';

interface BoxProps {
	onSelect: (item: Item, productType: ProductType) => void;
}

export default function VipBox({ onSelect }: BoxProps) {
	const { t } = useTranslation(['home', 'header']);
	const saleIsActive = process.env.NEXT_PUBLIC_SALE_IS_ACTIVE === 'true';

	return (
		<Box sx={style.backgroundContent}>
			<Stack sx={style.blockContent}>
				{/* HEADER Message Content */}
				<Stack sx={style.headerContent}>
					<Typography sx={style.headerTypo} variant="h1">
						{t('vipBox.headerTitle')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h1" marginTop={1}>
						{t('vipBox.headerDescription')}
					</Typography>
				</Stack>

				{/* CHOICE CONTENT */}
				<Stack sx={style.choiceBoxContent} marginTop={3}>
					{/* SILVER CONTENT */}
					<Stack sx={{ ...style.choiceContent }}>
						<Stack sx={{ flexDirection: 'column' }}>
							<Stack alignContent={'center'} sx={{ marginLeft: '37%', marginTop: '-80px', padding: '10px' }}>
								<Image width={66} height={0} src={IconSilverCard} alt="Silver Box" />
							</Stack>
							<Stack textAlign={'center'}>
								<Typography sx={{ ...style.silverGradient, fontSize: '3.1em' }}>{t('vipBox.silver.type')}</Typography>
								<Typography sx={{ fontSize: '1.9em', color: 'white' }}>{t('vipBox.silver.number')}</Typography>
							</Stack>
						</Stack>
						<Typography
							sx={{
								...style.silverGradient,
								textShadow: 'none',
								textAlign: 'center',
								margin: '20px auto 0',
								fontSize: '1em',
							}}
						>
							{t('vipBox.silver.access')}
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								fontSize: '1.3em',
								margin: '20px auto',
							}}
						>
							<PricesTag productType={ProductType.SILVER} />
						</Typography>
						<Button
							sx={{
								'&:hover': {
									borderColor: 'silver',
									fontWeight: 'bold',
									backgroundColor: 'white',
									color: '#020202',
									borderWidth: '1px',
									borderStyle: 'solid',
								},
							}}
							variant="outlined"
							onClick={() => {
								onSelect(SILVER_BOX, ProductType.SILVER);
							}}
							disabled={!saleIsActive}
						>
							{t('vipBox.silver.buyButton')}
						</Button>
					</Stack>

					{/* GOLD CONTENT */}
					<Stack sx={{ ...style.choiceContent }}>
						<Stack sx={{ flexDirection: 'column' }}>
							<Stack alignContent={'center'} sx={{ marginLeft: '37%', marginTop: '-80px', padding: '10px' }}>
								<Image width={66} height={0} src={IconGoldCard} alt="Gold Box" />
							</Stack>
							<Stack textAlign={'center'}>
								<Typography sx={{ ...style.goldGradient, fontSize: '3.1em' }}>{t('vipBox.gold.type')}</Typography>
								<Typography sx={{ fontSize: '1.9em', color: 'white' }}>{t('vipBox.gold.number')}</Typography>
							</Stack>
						</Stack>
						<Typography
							sx={{
								...style.goldGradient,
								textShadow: 'none',
								textAlign: 'center',
								margin: '20px auto 0',
								fontSize: '1em',
							}}
						>
							{t('vipBox.gold.access')}
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								fontSize: '1.3em',
								margin: '20px auto',
							}}
						>
							<PricesTag productType={ProductType.GOLD} />
						</Typography>
						<Button
							sx={{
								'&:hover': {
									borderColor: colorOr,
									fontWeight: 'bold',
									backgroundColor: 'white',
									color: '#020202',
									borderWidth: '1px',
									borderStyle: 'solid',
								},
							}}
							variant="outlined"
							onClick={() => {
								onSelect(GOLD_BOX, ProductType.GOLD);
							}}
							disabled={!saleIsActive}
						>
							{t('vipBox.gold.buyButton')}
						</Button>
					</Stack>

					{/* PLATINUM CONTENT */}
					<Stack sx={{ ...style.choiceContent }}>
						<Stack sx={{ flexDirection: 'column' }}>
							<Stack alignContent={'center'} sx={{ marginLeft: '37%', marginTop: '-80px', padding: '10px' }}>
								<Image width={66} height={0} src={IconPlatinumCard} alt="Platinum Box" />
							</Stack>
							<Stack textAlign={'center'}>
								<Typography sx={{ ...style.platinumGradient, fontSize: '2.7em' }}>
									{t('vipBox.platinum.type')}
								</Typography>
								<Typography sx={{ fontSize: '1.9em', color: 'white' }}>{t('vipBox.platinum.number')}</Typography>
							</Stack>
						</Stack>
						<Typography
							sx={{
								...style.platinumGradient,
								textShadow: 'none',
								textAlign: 'center',
								margin: '20px auto 0',
								fontSize: '1em',
							}}
						>
							{t('vipBox.platinum.access')}
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								fontSize: '1.3em',
								margin: '20px auto',
							}}
						>
							<PricesTag productType={ProductType.PLATINUM} />
						</Typography>
						<Button
							sx={{
								'&:hover': {
									borderColor: '#ebf8f8',
									fontWeight: 'bold',
									backgroundColor: 'white',
									color: '#020202',
									borderWidth: '1px',
									borderStyle: 'solid',
								},
							}}
							variant="outlined"
							onClick={() => {
								onSelect(PLATINUM_BOX, ProductType.PLATINUM);
							}}
							disabled={!saleIsActive}
						>
							{t('vipBox.platinum.buyButton')}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);
}

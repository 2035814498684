import { useRouter } from 'next/navigation';
import { Typography, Stack, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'next-i18next';
import FormBox from '../../../components/FormBox';
import TextField from '../../../components/TextField';
import { fetchPostJSON } from '../../../utils/api-helpers';
import ErrorField from '../../ErrorField';
import CheckBox from '../../CheckBox';

type Props = {
	logInClick: () => void;
};
export default function RegisterForm({ logInClick }: Props) {
	const { t } = useTranslation(['landing', 'navigation']);
	const router = useRouter();
	const [errorRegistration, setErrorRegistration] = useState<boolean>(false);
	const [emailPart, setEmailPart] = useState<boolean>(false);

	return (
		<FormBox>
			{emailPart ? (
				<Stack direction="column" spacing={8} px={4} alignItems="center" sx={{ height: '562px' }}>
					<Typography color="primary.main" variant="h1">
						{t('titleEmail')}
					</Typography>
					<Stack direction="column" spacing={4} alignItems="center">
						<Typography textAlign="center" variant="body1" sx={{ fontSize: 20 }}>
							{t('emailDescription1')}
						</Typography>
						<Typography textAlign="center" variant="body1" color="primary.main" sx={{ fontSize: 14 }}>
							{t('emailDescription2')}
						</Typography>
					</Stack>
					<Button sx={{ width: '200px' }} variant="contained">
						{t('resendEmail')}
					</Button>
				</Stack>
			) : (
				<>
					<Stack direction="row" spacing={2} justifyContent="center" mb={4}>
						<Button onClick={logInClick} sx={{ color: 'white' }}>
							{t('loginButton')}
						</Button>
						<Button sx={{ cursor: 'default' }} variant="outlined">
							{t('signinButton')}
						</Button>
					</Stack>
					<Formik
						initialValues={{
							name: '',
							email: '',
							password: '',
							referral: '',
							askCode: false,
						}}
						validationSchema={Yup.object({
							name: Yup.string().trim().required('Please enter your name').min(2, 'Too Short!').max(128, 'Too Long!'),
							email: Yup.string().email('Invalid email address').required('Please enter your email'),
							password: Yup.string().when('askCode', {
								is: false,
								then: (schema) => schema.min(2, 'Too Short!').required('Please enter your password'),
								otherwise: (schema) => schema.optional().nullable(),
							}),
							askCode: Yup.boolean(),
							referral: Yup.string().when('askCode', {
								is: false,
								then: (schema) => schema.trim().required('Please enter a valid code'),
								otherwise: (schema) => schema.optional().nullable(),
							}),
						})}
						onSubmit={async (values, { setSubmitting }) => {
							const res = await fetchPostJSON('/api/add_user', {
								name: values.name,
								email: values.email,
								password: values.password,
								referral: values.referral,
								askCode: values.askCode,
							});
							if (res?.error) {
								// setError(res.error);
								setErrorRegistration(true);
							} else {
								setEmailPart(true);
								setErrorRegistration(false);
							}
							if (res?.url) router.push(res.url);
							setSubmitting(false);
						}}
					>
						<Form>
							<TextField label={t('choosename')} name="name" />
							<ErrorField name="name" />
							<TextField label={t('youremail')} name="email" />
							<ErrorField name="email" />
							<TextField label={t('choosepassword')} name="password" type="password" />
							<ErrorField name="password" />
							<TextField label={t('referral')} name="referral" helpText={t('helpReferral')} />
							<Stack direction="row" spacing={2} mt={2}>
								<CheckBox name="askCode" />
								<Stack direction="column">
									<Typography variant="body1">{t('askCode1')}</Typography>
									<Typography variant="body1" color="primary.main" pt={1} fontSize={'0.9em'}>
										{t('askCode2')}
									</Typography>
								</Stack>
							</Stack>
							{errorRegistration && <Typography color="red">Error</Typography>}
							<Stack direction="row" justifyContent="center" paddingTop={4}>
								<Button sx={{ width: '200px' }} variant="contained" type="submit">
									{t('signIn', { ns: 'navigation' })}
								</Button>
							</Stack>
						</Form>
					</Formik>
				</>
			)}
		</FormBox>
	);
}

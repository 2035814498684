import { ProductType } from '../utils/types/product-type';

export const SILVER_BOX: Item = {
	id: 1111,
	productType: ProductType.SILVER,
	name: 'SILVER BOX',
	imgUrl: '/images/boxes/silver.png',
	logo: '/images/boxes/silver.png',
	number: 1111,
	qte: 10,
	short: '',
	description: 'Original Exclusive DAC Box Silver edition',
	price: {
		euro: 390,
		dollars: 390,
		matic: 390,
	},
};

export const GOLD_BOX: Item = {
	id: 2222,
	productType: ProductType.GOLD,
	name: 'GOLD BOX',
	imgUrl: '/images/boxes/gold.png',
	logo: '/images/boxes/gold.png',
	number: 2222,
	qte: 100,
	short: '',
	description: 'Original Exclusive DAC Box Gold edition',
	price: {
		euro: 3900,
		dollars: 3900,
		matic: 3900,
	},
};

export const PLATINUM_BOX: Item = {
	id: 3333,
	productType: ProductType.PLATINUM,
	name: 'PLATINUM BOX',
	imgUrl: '/images/boxes/platinum.png',
	logo: '/images/boxes/platinum.png',
	number: 2222,
	qte: 500,
	short: '',
	description: 'Original Exclusive DAC Box Platinum edition',
	price: {
		euro: 18500,
		dollars: 18500,
		matic: 18500,
	},
};

export const monuments = (): Piece[] => {
	return [
		{
			id: 1,
			title: 'Tour Eiffel',
			items: [
				{
					id: 12,
					productType: 0,
					name: `L'Embrasement`,
					poster: '/images/nfts/tour/12_HD.png',
					imgUrl: '/images/nfts/tour/12.mp4',
					logo: '/images/logos/M_1_LATOUREIFFEL.png',
					number: 1.2,
					author: 'Georges Garen',
					year: '1889',
					short:
						"La peinture \"L'embrasement de la tour Eiffel\" de Georges Garen représente la première illumination de la tour en 1889. C'est l'une des premières œuvres connues à représenter la tour. C’est une représentation réaliste de la tour, capturant l'excitation et l'optimisme de la fin du XIXe siècle.",
					shortEn:
						'The painting "The Illumination of the Eiffel Tower" by Georges Garen depicts the first lighting of the tower in 1889. It is one of the earliest known works to represent the tower. It\'s a realistic portrayal of the tower, capturing the excitement and optimism of the late 19th century.',
					description:
						'<b>CONTEXTE HISTORIQUE</b><br/>' +
						"<br/>L’Exposition Universelle de 1889 à Paris, célébrant le centenaire de la Révolution française, fut un événement de portée mondiale. Diffusant les nouvelles technologies et les avancées scientifiques, elle contribua à l'essor international de Paris en tant que métropole globale. La Tour en fer forgé érigée par l'architecte Gustave Eiffel lors de cette exposition devint l'emblème de la France moderne et de son industrie. Inaugurée le 31 mars 1889, la Tour Eiffel, d’abord objet de nombreuses polémiques, remporta bientôt un succès retentissant.<br/>" +
						'<br/><b>L’ARTISTE</b><br/>' +
						"<br/>Georges Garen, né à Paris en 1854, fut un peintre français. Diplômé de l'École des Beaux-Arts de Paris, il présenta ses œuvres dès 1879 au Salon des Artistes Français. Garen se distingua principalement par ses représentations de Paris, où la Tour Eiffel figura souvent. <br/>" +
						'<br/><b>L’ŒUVRE</b><br/>' +
						"<br/>\"L'embrasement de la Tour Eiffel\", peinte en 1889 par Georges Garen, illustre la Tour baignée de lumière grâce à des centaines de lampes à gaz. De style réaliste, elle capture l'ambiance festive de l'Exposition Universelle.<br/>" +
						'<br/><b>LE PETIT « PLUS »</b><br/>' +
						"<br/>La première illumination de la Tour Eiffel fut un événement d'une grande importance. Cette illumination symbolisa festivité et espoir pour les Français, la Tour étant vue comme un emblème de modernité et de grandeur nationale.<br/>" +
						"<br/>Choisi comme symbole représentatif de l'Exposition, le tableau fut reproduit en lithographie destinée au grand public grâce aux dernières avancées technologiques en matière d'impression - méthode qui fut même présentée en direct à la Galerie des Machines de l'exposition. Une œuvre qui perdure dans l'histoire et l'imaginaire collectif.<br/>",
					descriptionEn:
						'<b>HISTORICAL CONTEXT</b><br/>' +
						"<br/>The 1889 Universal Exposition in Paris, celebrating the centenary of the French Revolution, was a globally significant event. Showcasing new technologies and scientific advancements, it contributed to Paris's international prominence as a global metropolis. The wrought-iron tower erected by architect Gustave Eiffel during this exhibition became the emblem of modern France and its industry. Inaugurated on March 31, 1889, the Eiffel Tower, initially the subject of controversy, soon achieved resounding success.<br/>" +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/>Georges Garen, born in Paris in 1854, was a French painter. A graduate of the École des Beaux-Arts in Paris, he began presenting his works at the Salon des Artistes Français in 1879. Garen was notably known for his depictions of Paris, with the Eiffel Tower often featuring prominently.<br/>' +
						'<br/><b>THE ARTWORK</b><br/>' +
						'<br/>"The Illumination of the Eiffel Tower" painted in 1889 by Georges Garen, portrays the Tower aglow with light from hundreds of gas lamps. Done in a realistic style, it captures the festive atmosphere of the Universal Exposition.<br/>' +
						'<br/><b>TRIVIA</b><br/>' +
						'<br/>The first illumination of the Eiffel Tower was a significant event, symbolizing festivity and hope for the French, with the Tower seen as an emblem of modernity and national greatness.<br/>' +
						"<br/>Chosen as a representative symbol of the Exposition, the painting was reproduced as a lithograph for the general public, utilizing the latest printing advancements—a method even showcased in real-time at the Exposition's Gallery of Machines. An artwork that endures in history and collective imagination.<br/>",
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
				{
					id: 15,
					productType: 0,
					name: 'CryptoMon Tour Eiffel',
					poster: '/images/nfts/tour/15_HD.png',
					imgUrl: '/images/nfts/tour/15.mp4',
					logo: '/images/logos/M_1_LATOUREIFFEL.png',
					number: 1.5,
					author: 'R1-B',
					year: '2023',
					short:
						'Le CryptoMon est une œuvre évolutive !<br/><br/>' +
						'A chaque fois que vous compléterez la collection d’un monument, votre CryptoMon deviendra un peu plus doré.<br/><br/>' +
						'Cette évolution s’appliquera également à tous les autres CryptoMon en votre possession, même ceux représentant d’autres monuments.<br/><br/>' +
						'Plus votre CrypyoMon est doré, plus il est rare…',
					shortEn:
						'The CryptoMon is an evolutionary work!<br/><br/>' +
						'Each time you complete the collection of a monument, your CryptoMon will become a little more golden.<br/><br/>' +
						'This evolution will also apply to all other CryptoMon in your possession, even those representing other monuments.<br/><br/>' +
						'The more golden your CrypyoMon is, the rarer it is…',
					description:
						'Le CryptoMon est une œuvre évolutive !<br/><br/>' +
						'A chaque fois que vous compléterez la collection d’un monument, votre CryptoMon deviendra un peu plus doré.<br/><br/>' +
						'Cette évolution s’appliquera également à tous les autres CryptoMon en votre possession, même ceux représentant d’autres monuments.<br/><br/>' +
						'Plus votre CrypyoMon est doré, plus il est rare…<br/><br/><br/>' +
						'<b>CONTEXTE HISTORIQUE</b><br/>' +
						'<br/>Le CryptoMon a été créé pour le lancement de la collection <i>The 101 Experience</i>. Il est un clin d’œil aux collections d’Art digital comme les Cryptopunks ou les nombreuses autres séries de collectibles, aux représentations pixélisées.<br/>' +
						'<br/><b>L’ARTISTE</b><br/>' +
						'<br/>R1-B est un auteur depuis de nombreuses années, notamment en télévision.<br/>' +
						'<br/>Passionné de gaming, il est cofondateur de la société des auteurs de jeux, en France (SAJE).<br/>' +
						'<br/>Le CryptoMon est sa première œuvre digitale.<br/>' +
						'<br/>Le graphisme est réalisé par Julien Pommier.<br/>' +
						'<br/><b>L’ŒUVRE ET SON MÉCANISME</b><br/>' +
						'<br/>Parmi les 51 Digital Art Collectibles (DAC) de chaque monument, il y aura toujours un Cryptomon (Crypto Monument), édité à 25 001 exemplaires.<br/>' +
						'<br/>Et 101 CryptoMon, chacun édité à 25 001 exemplaires, dans l’ensemble de la collection The 101 Experience.<br/>' +
						'<br/>Le CryptoMon possède une capacité d’évolution et peut inclure de plus en plus de pixels dorés, à travers 101 états progressifs.<br/>' +
						'<br/>Plus les pixels dorés font partie des pixels totaux, plus l’exemplaire de CryptoMon est avancé et donc… plus rare.<br/>' +
						"<br/><b>PRINCIPE DE L'ÉVOLUTION</b><br/>" +
						'<br/>Au départ, chaque CryptoMon est au stade 0 (sans aucun pixel doré) .<br/>' +
						'<br/>A chaque fois que le collectionneur complète une collection – à date – d’un monument, le ou les CryptoMon qu’il possède augmentent d’un niveau (et cela définitivement). Donc un état plus doré.<br/>' +
						'<br/>Plus le collectionneur complète de collections plus son ou ses CryptoMon montent en niveaux.<br/>' +
						'<br/>Les CryptoMon revendus au second marché gardent toujours leurs niveaux acquis et peuvent continuer de monter de niveau si le nouveau propriétaire complète des collections qui n’ont pas déjà permis de faire évoluer le CryptoMon.<br/>',
					descriptionEn:
						'The CryptoMon is an evolutionary work!<br/><br/>' +
						'Each time you complete the collection of a monument, your CryptoMon will become a little more golden.<br/><br/>' +
						'This evolution will also apply to all other CryptoMon in your possession, even those representing other monuments.<br/><br/>' +
						'The more golden your CrypyoMon is, the rarer it is…<br/><br/><br/>' +
						'<b>HISTORICAL CONTEXT</b><br/>' +
						"<br/>The CryptoMon was created for the launch of The 101 Experience collection. It is a nod to digital art collections like Cryptopunks or the many other series of collectibles' pixelated representations.<br/>" +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/>R1-B has been an author for many years, especially in television.<br/>' +
						'<br/>Passionate about gaming, he is co-founder of the company of game authors in France (SAJE)<br/>' +
						'<br/>The CryptoMon is his first digital work.<br/>' +
						'<br/>The graphic design is made by Julien Pommier.<br/>' +
						'<br/><b>THE WORK AND ITS MECHANISM</b><br/>' +
						'<br/>Among the 51 Digital Art Collectibles (DAC) of each monument, there will always be a Cryptomon (Crypto Monument) and it will be issued at 25,001 copies.<br/>' +
						'<br/>And 101 CryptoMon, each issued at 25,001 copies, in the entire The 101 Experience collection<br/>' +
						'<br/>CryptoMon has the ability to evolve and can include more and more golden pixels, through 101 progressive states.<br/>' +
						'<br/>The more golden pixels are part of the total pixels, the more advanced the CryptoMon copy is and therefore… rarer.<br/>' +
						'<br/><b>PRINCIPLE OF EVOLUTION</b><br/>' +
						'<br/>Initially, each CryptoMon is at stage 0 (without any golden pixel). <br/>' +
						'<br/>Each time the collector completes a collection of a monument – to date – all CryptoMon he owns increase by one level (and that definitely). So a more golden state.<br/>' +
						'<br/>The more the collector completes different collections the more all his CryptoMon rise in levels.<br/>' +
						'<br/>CryptoMon resold in the second market always keep their acquired levels and can continue to rise if the new owner completes the collections that have not already allowed to evolve the Cryptomon.<br/>',
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
				{
					id: 16,
					productType: 0,
					name: 'Tour Eiffel aux arbres',
					imgUrl: '/images/nfts/tour/16_HD.png',
					logo: '/images/logos/M_1_LATOUREIFFEL.png',
					number: 1.6,
					author: 'Robert Delaunay',
					year: '1911',
					short:
						'La "Tour Eiffel aux arbres" de Robert Delaunay est une œuvre emblématique de l\'art moderne. Cette peinture simultanéiste de 1911 représente la Tour Eiffel enveloppée d\'arbres, dans une explosion de couleurs vives et contrastées. Les couleurs créent une danse visuelle, mettant en avant la tension entre la modernité de la Tour et la tranquillité des arbres.',
					shortEn:
						'The "Eiffel Tower with Trees" by Robert Delaunay is an iconic work of modern art. This 1911 simultaneist painting depicts the Eiffel Tower wrapped in trees, in an explosion of vivid and contrasting colors. The colors create a visual dance, highlighting the tension between the modernity of the Tower and the tranquility of the trees.',
					description:
						'<b>CONTEXTE HISTORIQUE</b><br/>' +
						"<br/>La \"Tour Eiffel aux arbres\", chef-d'œuvre de Robert Delaunay, nous plonge dans le début du XXe siècle, une époque d'innovation artistique, marquée par l'essor de l'art abstrait et des expérimentations audacieuses. Fasciné par la Tour Eiffel, symbole de modernité, Delaunay l'a représentée à plusieurs reprises.<br/>" +
						'<br/><b>L’ARTISTE</b><br/>' +
						"<br/>Robert Delaunay, figure majeure des mouvements cubistes et orphistes, est né à Paris en 1885. Attiré par l'abstraction et la puissance de la couleur, il a joué un rôle essentiel dans l'évolution de l'art abstrait. Ses explorations audacieuses dans le traitement de la lumière, de la couleur et du mouvement l'ont distingué en tant qu'innovateur de l'art moderne.<br/>" +
						'<br/><b>L’ŒUVRE</b><br/>' +
						'<br/>Réalisé en 1911, La "Tour Eiffel aux arbres" reflète le style simultané de Delaunay. La Tour Eiffel, qui lui était chère, se dresse dans un paysage boisé. Ce chef-d\'œuvre fusionne le tangible et l\'intangible, offrant un dialogue entre modernité et tradition, forme et couleur, et nature et progrès.<br/>' +
						'<br/><b>LE PETIT « PLUS »</b><br/>' +
						"<br/>Obsession de la Tour Eiffel : Delaunay a exprimé son obsession pour la Tour Eiffel dans plus d’une cinquantaine d’œuvres. La Tour est un symbole de son désir d'intégrer la modernité dans son art. Ce tableau a été exécuté alors que son auteur se trouvait loin de Paris, et qu’il a donc travaillé de mémoire.<br/>" +
						"<br/>Fenêtre comme symbole : La fenêtre est un motif puissant chez Delaunay. Elle représente l'intérieur et l'extérieur, le privé et le public et offre une perspective d'espoir.<br/>",
					descriptionEn:
						'<b>HISTORICAL CONTEXT</b><br/>' +
						'<br/>The “Eiffel Tower with Trees“ a masterpiece by Robert Delaunay, immerses us in the early 20th century, a time of artistic innovation marked by the rise of abstract art and daring experimentation. Enthralled by the Eiffel Tower, a symbol of modernity, Delaunay depicted it on multiple occasions.<br/>' +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/>Robert Delaunay, a prominent figure in the Cubist and Orphist movements, was born in Paris in 1885. Drawn to abstraction and the power of color, he played a crucial role in the evolution of abstract art. His bold explorations in light, color, and movement set him apart as an innovator of modern art.<br/>' +
						'<br/><b>THE ARTWORK</b><br/>' +
						"<br/>Created in 1911, the “Eiffel Tower with Trees“ reflects Delaunay's simultaneous style. The beloved Eiffel Tower stands within a wooded landscape. This masterpiece blends the tangible and intangible, providing a dialogue between modernity and tradition, form and color, and nature and progress.<br/>" +
						'<br/><b>TRIVIA</b><br/>' +
						'<br/>Eiffel Tower Obsession: Delaunay expressed his obsession with the Eiffel Tower in over fifty works. The Tower symbolized his desire to integrate modernity into his art. This painting was executed while its creator was far from Paris, so he worked from memory.<br/>' +
						"<br/>Window as a Symbol: The window is a potent motif in Delaunay's work. It represents both interior and exterior, private and public, offering a perspective of hope.<br/>",
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
			],
		},
		{
			id: 3,
			title: 'Arc de Triomphe',
			items: [
				{
					id: 31,
					productType: 0,
					name: "L'éléphant triomphal",
					imgUrl: '/images/nfts/arc/31_HD.png',
					logo: '/images/logos/M_3_ARCDETRIOMPHEDELETOIL.png',
					author: 'Charles Ribart',
					year: '1758',
					number: 3.1,
					short:
						"Explorez une histoire alternative de Paris avec cette pièce unique : l'éléphant triomphal, qui aurait pu trôner majestueusement à la place de l'Arc de Triomphe. Bien qu'il n'ait jamais vu le jour, son ambition artistique, ses majestueuses salles de spectacles intégrées et son audace architecturale captivent toujours.",
					shortEn:
						'Explore an alternative history of Paris with this unique piece: the triumphal elephant that could have majestically stood in place of the Arc de Triomphe. While it never came to fruition, its artistic ambition, integrated grand performance halls, and architectural audacity continue to captivate.',
					description:
						'<b>CONTEXTE HISTORIQUE</b><br/>' +
						"<br/>Le projet de construction d'un éléphant monumental remonte au XVIIIe siècle. Cette idée extravagante a germé dans la tête de l'ingénieur Jean-Etienne Ribart de Chamoust, sous le règne de Louis XV. Ce dernier rejeta finalement le projet craignant que la statue ne célèbre davantage la gloire d'Hannibal plutôt que ses propres victoires.<br/>" +
						"<br/>Lorsque Napoléon Ier monte sur le trône, il porte un regard neuf sur ce projet audacieux. Convaincu de sa faisabilité et souhaitant rendre hommage à sa Grande Armée, il envisage un temps de réaliser l'éléphant monumental pour trôner majestueusement à l’emplacement actuel de l’Arc de Triomphe. Mais son ministre de l'Intérieur, Jean-Baptiste Nompère de Champagny, l’en dissuade. La vision d'un éléphant gigantesque cède alors la place à celle du monument actuel. Et une part importante de la silhouette de Paris fut façonnée par ce choix historique.<br/>" +
						'<br/><b>L’ARTISTE</b><br/>' +
						'<br/><br/>' +
						'<br/><b>L’ŒUVRE</b><br/>' +
						"<br/>Imaginé comme une sculpture grandiose pour agrémenter les Champs Élysées, cet éléphant aurait également servi de château d'eau, avec une fontaine jaillissant de sa trompe tandis qu’à travers ses oreilles on aurait pu entendre la musique d’un orchestre logé dans sa tête !<br/>" +
						"À l'intérieur, des salles de concert, de danse et des appartements pour les visiteurs officiels étaient prévus, tandis qu'une statue du roi aurait surmonté l'ensemble, entourée de drapeaux et de statues de lions.<br/>" +
						'<br/><b>LE PETIT « PLUS »</b><br/>' +
						"<br/>L'histoire ne s’est pas arrêtée là ! Cet éléphant unique a tout de même vu le jour sous une forme éphémère, à un autre endroit emblématique de Paris : la Bastille. Projeté en bronze, mais seulement réalisé en plâtre en 1814, l'éléphant devint un témoin silencieux des transformations de la ville et de son histoire mouvementée. Pendant une trentaine d’années, il constitua un objet de curiosité qui suscita de nombreux commentaires et stimula l’imagination de Victor Hugo dans <i>Les Misérables</i>. Il sera finalement détruit et remplacé en 1846 par la colonne de Juillet.<br/>",
					descriptionEn:
						'<b>HISTORICAL CONTEXT</b><br/>' +
						"<br/>The ambitious project of constructing a monumental elephant dates back to the 18th century. This extravagant idea originated in the mind of engineer Jean-Etienne Ribart de Chamoust, during the reign of Louis XV. However, Louis XV ultimately rejected the project, fearing that the statue would celebrate Hannibal's glory more than his own victories.<br/>" +
						"<br/>When Napoleon I took the throne, he looked at this audacious project with fresh eyes. Convinced of its feasibility and eager to pay homage to his “Grande Armée”, he briefly considered creating the monumental elephant to stand majestically in the current location of the Arc de Triomphe. However, his Minister of the Interior, Jean-Baptiste Nompère de Champagny, dissuaded him from the idea. The vision of a colossal elephant was replaced by that of the current monument. A significant part of Paris's silhouette was shaped by this historical decision.<br/>" +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/><br/>' +
						'<br/><b>THE ARTWORK</b><br/>' +
						'<br/>Conceived as a grand sculpture to adorn the Champs Élysées, this elephant would have also served as a water tower, with a fountain gushing from its trunk while music played from an orchestra housed within its head through its ears! Inside, concert halls, dance halls, and apartments for official visitors were planned, with a statue of the king crowning the ensemble, surrounded by flags and statues of lions.<br/>' +
						'<br/><b>TRIVIA</b><br/>' +
						"<br/>The story didn't stop there! This unique elephant still came to life in an ephemeral form, at another iconic location in Paris: the Bastille. Conceived in bronze but only realized in plaster in 1814, the elephant became a silent witness to the city's transformations and tumultuous history. For about thirty years, it was an object of curiosity that sparked numerous discussions and fueled Victor Hugo's imagination in <i>Les Misérables</i>. Eventually, it was destroyed and replaced in 1846 by the July Column.<br/>",
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
				{
					id: 32,
					productType: 0,
					name: 'CryptoMon 3.2',
					poster: '/images/nfts/arc/32_HD.png',
					imgUrl: '/images/nfts/arc/32.mp4',
					logo: '/images/logos/M_3_ARCDETRIOMPHEDELETOIL.png',
					number: 3.2,
					author: 'R1-B',
					year: '2023',
					short:
						'Le CryptoMon est une œuvre évolutive !<br/><br/>' +
						'A chaque fois que vous compléterez la collection d’un monument, votre CryptoMon deviendra un peu plus doré.<br/><br/>' +
						'Cette évolution s’appliquera également à tous les autres CryptoMon en votre possession, même ceux représentant d’autres monuments.<br/><br/>' +
						'Plus votre CrypyoMon est doré, plus il est rare…',
					shortEn:
						'The CryptoMon is an evolutionary work!<br/><br/>' +
						'Each time you complete the collection of a monument, your CryptoMon will become a little more golden.<br/><br/>' +
						'This evolution will also apply to all other CryptoMon in your possession, even those representing other monuments.<br/><br/>' +
						'The more golden your CrypyoMon is, the rarer it is…',
					description:
						'Le CryptoMon est une œuvre évolutive !<br/><br/>' +
						'A chaque fois que vous compléterez la collection d’un monument, votre CryptoMon deviendra un peu plus doré.<br/><br/>' +
						'Cette évolution s’appliquera également à tous les autres CryptoMon en votre possession, même ceux représentant d’autres monuments.<br/><br/>' +
						'Plus votre CrypyoMon est doré, plus il est rare…<br/><br/><br/>' +
						'<b>CONTEXTE HISTORIQUE</b><br/>' +
						'<br/>Le CryptoMon a été créé pour le lancement de la collection <i>The 101 Experience</i>. Il est un clin d’œil aux collections d’Art digital comme les Cryptopunks ou les nombreuses autres séries de collectibles, aux représentations pixélisées.<br/>' +
						'<br/><b>L’ARTISTE</b><br/>' +
						'<br/>R1-B est un auteur depuis de nombreuses années, notamment en télévision.<br/>' +
						'<br/>Passionné de gaming, il est cofondateur de la société des auteurs de jeux, en France (SAJE).<br/>' +
						'<br/>Le CryptoMon est sa première œuvre digitale.<br/>' +
						'<br/>Le graphisme est réalisé par Julien Pommier.<br/>' +
						'<br/><b>L’ŒUVRE ET SON MÉCANISME</b><br/>' +
						'<br/>Parmi les 51 Digital Art Collectibles (DAC) de chaque monument, il y aura toujours un Cryptomon (Crypto Monument), édité à 25 001 exemplaires.<br/>' +
						'<br/>Et 101 CryptoMon, chacun édité à 25 001 exemplaires, dans l’ensemble de la collection The 101 Experience.<br/>' +
						'<br/>Le CryptoMon possède une capacité d’évolution et peut inclure de plus en plus de pixels dorés, à travers 101 états progressifs.<br/>' +
						'<br/>Plus les pixels dorés font partie des pixels totaux, plus l’exemplaire de CryptoMon est avancé et donc… plus rare.<br/>' +
						"<br/><b>PRINCIPE DE L'ÉVOLUTION</b><br/>" +
						'<br/>Au départ, chaque CryptoMon est au stade 0 (sans aucun pixel doré) .<br/>' +
						'<br/>A chaque fois que le collectionneur complète une collection – à date – d’un monument, le ou les CryptoMon qu’il possède augmentent d’un niveau (et cela définitivement). Donc un état plus doré.<br/>' +
						'<br/>Plus le collectionneur complète de collections plus son ou ses CryptoMon montent en niveaux.<br/>' +
						'<br/>Les CryptoMon revendus au second marché gardent toujours leurs niveaux acquis et peuvent continuer de monter de niveau si le nouveau propriétaire complète des collections qui n’ont pas déjà permis de faire évoluer le CryptoMon.<br/>',
					descriptionEn:
						'The CryptoMon is an evolutionary work!<br/><br/>' +
						'Each time you complete the collection of a monument, your CryptoMon will become a little more golden.<br/><br/>' +
						'This evolution will also apply to all other CryptoMon in your possession, even those representing other monuments.<br/><br/>' +
						'The more golden your CrypyoMon is, the rarer it is…<br/><br/><br/>' +
						'<b>HISTORICAL CONTEXT</b><br/>' +
						"<br/>The CryptoMon was created for the launch of The 101 Experience collection. It is a nod to digital art collections like Cryptopunks or the many other series of collectibles' pixelated representations.<br/>" +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/>R1-B has been an author for many years, especially in television.<br/>' +
						'<br/>Passionate about gaming, he is co-founder of the company of game authors in France (SAJE)<br/>' +
						'<br/>The CryptoMon is his first digital work.<br/>' +
						'<br/>The graphic design is made by Julien Pommier.<br/>' +
						'<br/><b>THE WORK AND ITS MECHANISM</b><br/>' +
						'<br/>Among the 51 Digital Art Collectibles (DAC) of each monument, there will always be a Cryptomon (Crypto Monument) and it will be issued at 25,001 copies.<br/>' +
						'<br/>And 101 CryptoMon, each issued at 25,001 copies, in the entire The 101 Experience collection<br/>' +
						'<br/>CryptoMon has the ability to evolve and can include more and more golden pixels, through 101 progressive states.<br/>' +
						'<br/>The more golden pixels are part of the total pixels, the more advanced the CryptoMon copy is and therefore… rarer.<br/>' +
						'<br/><b>PRINCIPLE OF EVOLUTION</b><br/>' +
						'<br/>Initially, each CryptoMon is at stage 0 (without any golden pixel). <br/>' +
						'<br/>Each time the collector completes a collection of a monument – to date – all CryptoMon he owns increase by one level (and that definitely). So a more golden state.<br/>' +
						'<br/>The more the collector completes different collections the more all his CryptoMon rise in levels.<br/>' +
						'<br/>CryptoMon resold in the second market always keep their acquired levels and can continue to rise if the new owner completes the collections that have not already allowed to evolve the Cryptomon.<br/>',
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
				{
					id: 33,
					productType: 0,
					name: `48°52'25.52"N 2°17'41.8"E`,
					poster: '/images/nfts/arc/33_HD.png',
					imgUrl: '/images/nfts/arc/33.mp4',
					logo: '/images/logos/M_3_ARCDETRIOMPHEDELETOIL.png',
					number: 3.3,
					author: 'FELIXFELIXFELIX',
					year: '2023',
					short:
						'Réalisée spécialement pour <i>The 101 Experience</i>, l’œuvre intitulée « 48°52\'25.52"N 2°17\'41.8"E – Marianne » de FelixFelixFelix est un portrait de l’Arc de triomphe personnifié, qui devient ainsi un « Urbanoïde », vivant et interagissant avec son environnement urbain proche. Le titre correspond aux coordonnées géographiques de l’Arc de triomphe.',
					shortEn:
						'Produced especially for The 101 Experience, the work entitled "48°52\'25.52"N 2°17\'41.8"E - Marianne" realized by FelixFelixFelix is a portrait of the personified Arc de triomphe, which thus becomes an "Urbanoid", living and interacting with its nearby urban environment. The title corresponds to the geographical coordinates of the Arc de triomphe.',
					description:
						'<b>CONTEXTE</b><br/>' +
						'<br/>« 48°52\'25.52"N 2°17\'41.8"E », réalisée spécialement pour The 101 Experience, s’inscrit dans une série créée et pensée par FelixFelixFelix : « Urbanoids » (https://www.felixfelixfelix.com/series/14).' +
						"Chaque œuvre est composée d'éléments urbains tous situés autour d'un point de la terre. Leurs titres sont les coordonnées qui permettent de découvrir d'où ils viennent.<br/>" +
						'<br/><br/>' +
						'<br/><b>L’ŒUVRE</b><br/>' +
						'<br/>L’œuvre « 48°52\'25.52"N 2°17\'41.8"E » offre une représentation décalée d’un monument tellement connu et vu qu’on ne le regarde plus vraiment. Sa personnifaction en « Urbanoïde » permet de porter sur lui un regard neuf et amusé. Ses réagencements animés le montre interagissant avec son environnement urbain.Le titre correspond aux coordonnées géographiques de l’Arc de triomphe.<br/>' +
						'<br/><br/>' +
						'<br/><b>L’ARTISTE</b><br/>' +
						"<br/>FelixFelixFelix a étudié l’architecture à Paris. Son travail architectural inspire son travail créatif et les deux disciplines s’enrichissent mutuellement. Dessinateur passionné, qui privilégie les grands formats noir et blanc, il se concentre sur l'étude de l'espace, à travers sa représentation et sa signification. Il explore la relation entre le macro et le micro, en interprétant l'expérience de leur interaction.<br/>",
					descriptionEn:
						'<b>CONTEXT</b><br/>' +
						'<br/>"48°52\'25.52"N 2°17\'41.8"E", specially produced for The 101 Experience, is part of a series created and designed by FelixFelixFelix: "Urbanoïds" (https://www.felixfelixfelix.com/series/14). Each work is composed of urban elements all located around a point of the earth. Their titles are the coordinates that allow us to find out where they come from.<br/>' +
						'<br/><br/>' +
						'<br/><b>THE ARTWORK</b><br/>' +
						'<br/>The work "48°52\'25.52"N 2°17\'41.8"E" offers an offbeat representation of a monument so well known and seen that we no longer really look at it. His personification as an "Urbanoid" allows us to take a fresh and amused look at him. Its animated rearrangements show it interacting with its urban environment. The title corresponds to the geographical coordinates of the Arc de Triomphe.<br/>' +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/>FelixFelixFelix studied architecture in Paris. His architectural work inspires his creative work and the two disciplines enrich each other. Passionate draftsman, who favors large black and white formats, he focuses on the study of space, through its representation and meaning. It explores the relationship between the macro and the micro, interpreting the experience of their interaction.<br/>',
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
				{
					id: 34,
					productType: 0,
					name: `L'Arc de Triomphe - En négatif / Sur verre`,
					poster: '/images/nfts/arc/34_HD.png',
					imgUrl: '/images/nfts/arc/34.mp4',
					logo: '/images/logos/M_3_ARCDETRIOMPHEDELETOIL.png',
					number: 3.4,
					short:
						"“L'Arc de Triomphe” de Gustave Le Gray est une photographie historique et emblématique de Paris et du monument. Elle a été prise en 1859, à une époque où la photographie était encore une nouvelle technologie. L'auteur est considéré comme l'un des pionniers de la photographie moderne.",
					shortEn:
						'“L’Arc de Triomphe” by Gustave Le Gray is a historical and emblematic photograph of Paris and the monument. It was taken in 1859, at a time when photography was still a new technology. The author is considered one of the pioneers of modern photography.',
					author: 'Gustave Le Gray',
					year: '1859',
					description:
						'<b>CONTEXTE HISTORIQUE</b><br/>' +
						'<br/>Gustave Le Gray est l’un des photographes majeurs du XIX ème siècle, célébré notamment pour ses Marines qui comptent parmi les grands chefs d’œuvres de l’histoire de cet art. Il est l’auteur de la première photographie officielle d’un chef de l’État français, Louis-Napoléon Bonaparte, avant de devenir le photographe officiel de la famille impériale. En 1851, il est choisi par la commission des Monuments historiques, dans le cadre de la Mission héliographique — premier inventaire photographique du patrimoine architectural de la France.<br/>' +
						'<br/>Il réalise de nombreuses photos de monuments célèbres, tels que la cathédrale Notre-Dame de Paris, le château de Versailles et le Mont Saint-Michel.<br/>' +
						'<br/><b>L’ARTISTE</b><br/>' +
						"<br/>Gustave Le Gray est né le 30 août 1820 à Villiers-le-Bel, en France. Il étudie la peinture à l'École des Beaux-Arts de Paris et commence à s'intéresser à la photographie en 1848. Il expérimente différentes techniques et développe un nouveau procédé de développement des négatifs qui lui permet de prendre des photos plus claires et plus détaillées.<br/>" +
						'<br/>Gustave Le Gray a eu une grande influence sur l’évolution de la photographie vers un <i>sixième art</i>. Alexandre Dumas disait de lui : « <i>J’ai compris que le photographe comme Le Gray est à la fois un artiste et un savant</i> ». <br/>' +
						'<br/><b>L’ŒUVRE</b><br/>' +
						"<br/>La neige qui recouvre l'Arc lui donne un aspect surréaliste et lui confère une dimension presque religieuse. La photo a été prise lors d'une forte tempête de neige. Le Gray a dû se battre contre le vent et le froid pour obtenir son cliché.<br/>" +
						"<br/>Cette oeuvre est l'une des plus considérables du XIXe siècle, dont l'histoire de l'art retiendra qu'elle constitue une puissante source d'inspiration de la révolution impressionniste.<br/>" +
						'<br/><b>LE PETIT « PLUS »</b><br/>' +
						"<br/>Le Gray a saisi cette emblématique structure parisienne en utilisant sa technique ingénieuse du \"papier ciré\". Ce procédé innovant lui a permis de fusionner deux négatifs distincts, l'un pour le ciel et l'autre pour la structure, créant ainsi un équilibre parfait d'exposition. Cet exploit technologique marque le début d'une ère nouvelle de représentation artistique et documentaire.<br/>" +
						"<br/>Cette première photographie de l'Arc de Triomphe est devenue un symbole éloquent de la fusion entre créativité artistique et avancée technologique, annonçant le potentiel illimité de l'art photographique et ouvrant la voie à des horizons artistiques encore inexplorés.<br/>",
					descriptionEn:
						'<b>HISTORICAL CONTEXT</b><br/>' +
						'<br/>Gustave Le Gray is one of the major photographers of the 19th century, celebrated in particular for his Marines which are among the great masterpieces in the history of this art. He is the author of the first official photograph of a French head of state, Louis-Napoleon Bonaparte, before becoming the official photographer of the imperial family. In 1851, he was chosen by the Historic Monuments Commission, as part of the Heliographic Mission — the first photographic inventory of the architectural heritage of France.<br/>' +
						'<br/>He takes many photos of famous monuments, such as Notre-Dame de Paris Cathedral, the Palace of Versailles and Mont Saint-Michel.<br/>' +
						'<br/><b>THE ARTIST</b><br/>' +
						'<br/>Gustave Le Gray was born on August 30, 1820 in Villiers-le-Bel, France. He studied painting at the Ecole des Beaux-Arts in Paris and began to take an interest in photography in 1848. He experimented with different techniques and developed a new process for developing negatives which allowed him to take clearer and more detailed.<br/>' +
						'<br/>Gustave Le Gray had a great influence on the evolution of photography towards a sixth art. Alexandre Dumas said of him: "<i>I understood that the photographer like Le Gray is both an artist and a scholar</i>".<br/>' +
						'<br/><b>THE ARTWORK</b><br/>' +
						'<br/>The snow that covers the Arc gives it a surreal aspect and gives it an almost religious dimension. The photo was taken during a heavy snowstorm. Le Gray had to fight against the wind and the cold to get his shot.<br/>' +
						'<br/>This work is one of the most considerable of the 19th century, of which the history of art will remember that it constitutes a powerful source of inspiration for the Impressionist revolution.<br/>' +
						'<br/><b>TRIVIA</b><br/>' +
						'<br/>Le Gray captured this iconic Parisian structure using his ingenious "wax paper" technique. This innovative process allowed him to merge two separate negatives, one for the sky and the other for the structure, thus creating a perfect balance of exposure. This technological feat marks the beginning of a new era of artistic and documentary representation.<br/>' +
						'<br/>This first photograph of the Arc de Triomphe has become an eloquent symbol of the fusion between artistic creativity and technological advancement, announcing the unlimited potential of photographic art and opening the way to artistic horizons that have yet to be explored.<br/>',
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
			],
		},
		{
			id: 5,
			title: 'Mont Saint-Michel',
			items: [
				{
					id: 52,
					productType: 0,
					name: "Le Mont Saint-Michel sous l'orage",
					imgUrl: '/images/nfts/mont/52_HD.png',
					logo: '/images/logos/M_2_ABBAYEDUMONTSAINTMICHE.png',
					author: 'Théodore Gudin',
					year: '1830',
					number: 5.2,
					short:
						'Entre exaltation dramatique et vision hallucinée, cette œuvre a été réalisée par l’un des premiers peintres de la Marine royale, Théodore Gudin. Elle fait de l’abbaye le lieu par excellence du sublime et de la sensibilité romantique.',
					shortEn:
						'Between dramatic exaltation and hallucinatory vision, this work was created by one of the first painters of the Royal Navy, Théodore Gudin. It makes the abbey the place par excellence of the sublime and romantic sensibility.',
					description:
						'<b>CONTEXTE HISTORIQUE</b>' +
						'<br/>' +
						'<br/>En 1830, si le Mont-Saint-Michel n’avait rien perdu de son caractère mythique du lié à son importance historique, spirituelle et esthétique, son état de dégradation et son utilisation en tant que prison avaient en revanche terni sa noblesse. L’œuvre de Theodore Gudin est représentative de ces contrastes. Elle les accentue encore pour en renforcer la puissance dramatique et l’aura de mystère, faisant du site un emblème du sublime romantique.<br/>' +
						'<br/><b>L’ARTISTE</b>' +
						'<br/>' +
						"<br/>Théodore Gudin, peintre romantique du XIXe siècle, ayant, dans sa jeunesse été formé au Collège Royal de la Marine et navigué dans la marine américaine, est célèbre pour ses scènes marines et portuaires, tempêtes, orages et autres naufrages, qui manifestent un sens remarquable des effets de construction dramatique et de contrastes de lumière. Peintre officiel de la Marine, sous la Monarchie de Juillet et le Second Empire - ce qui lui vaudra notamment les sarcasmes de Baudelaire - il a largement contribué à la préservation de l'histoire maritime française.<br/>" +
						'<br/><b>L’ŒUVRE</b>' +
						'<br/>' +
						"<br/>\"Le Mont-Saint-Michel sous l'orage\" représente l'abbaye dans une ambiance dramatique et sublime. Des personnages luttent pour atteindre l'île sous la tempête, tandis que l'abbaye au sommet est éclairée d'une lumière dorée, créant une scène apocalyptique. Cette toile incarne la tendance romantique à redécouvrir le Moyen Âge et à exprimer le sublime à travers des paysages tourmentés.<br/>" +
						'<br/><b>LE PETIT « PLUS »</b>' +
						'<br />' +
						'<br/>Le Mont-Saint-Michel a abrité dans ses murs majestueux, jusqu’en 1863 une prison où furent, notamment, enfermés des opposants politiques comme Blanqui ou Barbès. Victor Hugo évoquait ainsi ce contraste entre les deux dimensions du site, celle des ténèbres du cachot et celle de la lumière spirituelle : « <i>Figure-toi une prison, ce je ne sais quoi de difforme et de fétide qu’on appelle une prison, installée dans cette magnifique enveloppe du prêtre et du chevalier au quatorzième siècle. Un crapaud dans un reliquaire.</i> »<br/>',
					descriptionEn:
						'<b>HISTORICAL CONTEXT</b>' +
						'<br/>' +
						'<br/>In 1830, if Mont-Saint-Michel had lost none of its mythical character linked to its historical, spiritual and aesthetic importance, its state of degradation and its use as a prison had on the other hand tarnished its nobility. The work of Theodore Gudin is representative of these contrasts. She accentuates them further to reinforce their dramatic power and aura of mystery, making the site an emblem of the romantic sublime.<br/>' +
						'<br/><b>THE ARTIST</b>' +
						'<br/>' +
						'<br/>Théodore Gudin, romantic painter of the 19th century, having, in his youth, been trained at the Royal College of the Navy and sailed in the American navy, is famous for his marine and port scenes, storms, storms and other shipwrecks, which manifest a remarkable sense dramatic building effects and light contrasts. Official painter of the Navy, under the July Monarchy and the Second Empire - which earned him in particular the sarcasm of Baudelaire - he largely contributed to the preservation of French maritime history.<br/>' +
						'<br/><b>THE ARTWORK</b>' +
						'<br/>' +
						'<br/>"Le Mont-Saint-Michel sous l\'orage" represents the abbey in a dramatic and sublime atmosphere. Figures struggle to reach the island in the storm, while the abbey at the top is lit with golden light, creating an apocalyptic scene. This painting embodies the romantic tendency to rediscover the Middle Ages and to express the sublime through tormented landscapes.<br/>' +
						'<br/><b>TRIVIA</b>' +
						'<br />' +
						'<br/>Mont-Saint-Michel housed within its majestic walls, until 1863, a prison where, in particular, political opponents such as Blanqui and Barbès were imprisoned. Victor Hugo thus evoked this contrast between the two dimensions of the site, that of the darkness of the dungeon and that of the spiritual light: « <i>Imagine a prison, something deformed and fetid that is called a prison, installed in this magnificent envelope of the priest and the knight in the fourteenth century. A toad in a reliquary.</i> »<br/>',
					price: {
						euro: 19,
						dollars: 20,
						matic: 29,
					},
				},
			],
		},
	];
};

import { Typography } from '@mui/material';
import { ErrorMessage } from 'formik';

type Props = {
	name: string;
};

export default function ErrorField({ name, ...rest }: Props) {
	return (
		<Typography color="primary.main" marginTop={1} fontSize={'0.9em'}>
			<ErrorMessage name={name} {...rest} />
		</Typography>
	);
}

import useSWR from 'swr';
import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
	if (!stripePromise) {
		stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);
	}
	return stripePromise;
};

const fetcher = async (url: string) => {
	const res = await fetch(url);
	if (!res.ok) {
		const e = await res.json();
		const error = new Error(e.message);
		error.stack = e.error;
		error.name = e.code;
		throw error;
	}
	return res.json();
};

export function getStripeTransaction(stripeTransactionId: string, refreshInterval: number = 60000) {
	const { data, error, isLoading, isValidating, mutate } = useSWR(
		stripeTransactionId ? `/api/get_stripe_transaction?stripeTransactionId=${stripeTransactionId}` : null,
		fetcher,
		{ refreshInterval: refreshInterval }
	);
	return {
		transaction: data?.[0],
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}

export default getStripe;

import React, { useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Button, Checkbox, Link, Stack, Typography } from '@mui/material';
import MaticIcon from './img/matic.svg';
import buyStyles from './Buy.style';
import FormBox from '../../../components/FormBox';
import PricesTag from '../../PricesTag/PricesTag';
import { ProductType } from '../../../utils/types/product-type';
import { CurrencyType } from '../../../utils/types/currency-type';

interface BuyProps {
	itemBuy: Item;
	selectedProductType: ProductType;
	onSelect: (selected: Item, currency: CurrencyType) => void;
}

function Buy({ itemBuy, selectedProductType, onSelect }: BuyProps) {
	const { t, i18n } = useTranslation(['home', 'header']);
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [pleaseAcceptCG, setPleaseAcceptCG] = useState<boolean>(false);

	function handleSelection(item: Item, currency: CurrencyType) {
		if (!isChecked) {
			setPleaseAcceptCG(true);
			return;
		}
		onSelect(item, currency);
	}

	return (
		<FormBox>
			<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
				<Typography
					variant="h1"
					color={'primary.light'}
					sx={{
						...buyStyles.priceText,
						marginTop: '0',
						paddingBottom: '10px',
						textTransform: 'uppercase',
						textShadow: '0px 0px 20px #AD9579',
					}}
				>{`${itemBuy.name}`}</Typography>
				<img
					height={150}
					src={!itemBuy.imgUrl.endsWith('.mp4') ? (itemBuy.imgUrl as string) : (itemBuy.poster as string)}
					alt={itemBuy.name}
					style={buyStyles.imgContainer}
				/>
				<Typography variant="h2" color={'primary.light'} sx={buyStyles.priceText}>
					<PricesTag productType={selectedProductType} />
				</Typography>

				<Stack flexDirection="row" sx={buyStyles.currencyButtonContainer}>
					<Typography sx={{ ...buyStyles.currencyButton, ...buyStyles.euroButton }}>€</Typography>
					<Button
						variant="outlined"
						sx={{ width: '200px' }}
						onClick={() => {
							handleSelection(itemBuy, CurrencyType.EUR);
						}}
					>
						{t('PAYER EN EUROS')}
					</Button>
				</Stack>

				<Stack flexDirection="row" sx={buyStyles.currencyButtonContainer}>
					<Typography sx={{ ...buyStyles.currencyButton, ...buyStyles.dollarsButton }}>$</Typography>
					<Button
						variant="outlined"
						sx={{ width: '200px' }}
						onClick={() => {
							handleSelection(itemBuy, CurrencyType.USD);
						}}
					>
						{t('PAYER EN DOLLARS')}
					</Button>
				</Stack>

				<Stack flexDirection="row" sx={buyStyles.maticButtonContainer}>
					{/*@ts-ignore*/}
					<Image src={MaticIcon.src} width={60} height={60} style={buyStyles.maticIcon} alt={'Matic'} />
					<Button
						variant="outlined"
						sx={{ width: '200px' }}
						onClick={() => {
							handleSelection(itemBuy, CurrencyType.MATIC);
						}}
					>
						{t('PAYER EN MATICS')}
					</Button>
				</Stack>

				<Stack flexDirection="row" sx={buyStyles.termsContainer}>
					<Checkbox onChange={(event) => setIsChecked(event.target.checked)} checked={isChecked} />

					<Link href="/terms" underline={'hover'} target="_blank">
						<Typography sx={buyStyles.termsText}>
							{t("J'ai lu et accepte l'ensemble des Conditions Générales")}
						</Typography>
					</Link>
				</Stack>

				<Stack flexDirection="row" textAlign={'center'}>
					{!isChecked && pleaseAcceptCG && (
						<Typography variant="h3" color="primary.main">
							{t('Please accept the terms and conditions')}
						</Typography>
					)}
				</Stack>
			</Stack>
		</FormBox>
	);
}

export default Buy;

import useSWR from 'swr';

const fetcher = async (url) => {
	const res = await fetch(url);
	if (!res.ok) {
		const e = await res.json();
		const error = new Error(e.message);
		error.error = e.error;
		error.code = e.code;
		throw error;
	}
	return res.json();
};

export function getPricing(quantity) {
	const { data, error, isLoading, isValidating, mutate } = useSWR(
		quantity ? `/api/get_pricing?quantity=${quantity}` : null,
		fetcher
	);
	return {
		prices: data,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}

export function getPricesAndSupply() {
	const { data, error, isLoading, isValidating, mutate } = useSWR(`/api/get_supply`, fetcher);
	return {
		currentSupply: data?.currentSupply,
		saleIsActive: data?.saleIsActive,
		prices: data?.prices,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}

export function getPreOrder(tokenId, refreshInterval = 60000) {
	const { data, error, isLoading, isValidating, mutate } = useSWR(
		tokenId ? `/api/get_preorder?tokenId=${tokenId}` : null,
		fetcher,
		{ refreshInterval: refreshInterval }
	);
	return {
		preOrder: data,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}

import { Stack, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'next-i18next';
import FormBox from '../../../components/FormBox';
import payStyles from './PayRef.style';

interface PayRefProps {
	onBack: () => void;
}

export default function PayRef({ onBack }: PayRefProps) {
	const { t } = useTranslation(['home']);

	return (
		<FormBox>
			<Stack sx={payStyles.contained}>
				<HighlightOffIcon sx={payStyles.iconRef} />
				<Typography sx={payStyles.typo}>{t('MSG_PAY_REF')}</Typography>
				<Typography sx={payStyles.back} onClick={onBack} textTransform={'uppercase'}>
					{t('BACK_HOME')}
				</Typography>
			</Stack>
		</FormBox>
	);
}

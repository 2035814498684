// Home.style.tsx

import { colorOr } from '../../themes/theme';

const styles = {
	blockContent: {
		direction: 'column',
		flexDirection: 'column',
		flex: 1,
		margin: 'auto',
	},
	headerContent: {
		justifyContent: 'center',
		alignItems: 'center',
		spacing: 1,
		padding: '20px 15px',
		flexDirection: 'column',
		textAlign: 'center',
	},
	priceContent: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: '30px',
		paddingRight: '30px',
		paddingBottom: '5px',
		paddingTop: '10px',
		flexDirection: 'column',
		textAlign: 'center',
		borderRadius: '7px',
		marginTop: '50px',
		marginBottom: '50px',
		// backgroundColor: '#141617',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: 'primary.main',
	},
	vipContentText: {
		flex: 3,
		flexDirection: 'row',
		minWidth: '292px',
		maxWidth: '1280px',
	},
	iconButton: {
		left: '5px',
		position: 'absolute',
		height: '25px',
	},
	buttonGalerie: {
		width: '250px',
	},
	buttonCollection: {
		marginLeft: '20px',
		width: '250px',
	},
	buttonContent: {
		maxWidth: '1280px',
		padding: '0px',
		flex: 1,
		margin: 'auto',
	},
	tabContent: {
		margin: '16px',
		flexWrap: 'wrap',
		marginTop: '40px',
		marginBottom: '25px',
	},
	itemContent: {
		maxWidth: '1280px',
		overflowX: 'hidden',
		width: '100%',
		margin: 'auto',
	},
	headerTypo: {
		fontSize: '26px',
		fontWeight: 'normal',
		color: 'primary.light',
		textTransform: 'uppercase',
	},
	headerTypoSub: {
		fontWeight: 'normal',
		color: 'primary.light',
	},
	vipContent: {
		m: 2,
		backgroundColor: 'primary.dark',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: 'primary.main',
		padding: '30px',
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	vip: {
		color: colorOr,
		fontSize: '22px',
		fontWeight: 400,
	},
	vipMessage: {
		marginTop: '10px',
		fontSize: '15',
		fontWeight: 400,
		margin: '15px 0',
	},
	iconCard: {
		width: '45px',
		marginRight: '10px',
	},
	carouselContent: {
		height: '100vh',
		width: '100vw',
		//position: 'fixed',
		top: '0',
		//padding: '40px',
		paddingTop: '10px',
	},
	cardContent: { flex: 0 },
	typoContent: { flex: 3 },
	vipButton: { lineHeight: '15px', minWidth: '250px' },
};

export default styles;

// WhatVipBox.style.tsx

import { colorOr } from '../../../themes/theme';

const styles = {
	backgroundContent: {
		//maxWidth: '990px',
		backgroundColor: '#bcb4ac',
		//backgroundImage: 'url("/images/background.png")',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		paddingBottom: '50px',
	},
	blockContent: {
		direction: 'column',
		flexDirection: 'column',
		flex: 1,
		maxWidth: '1280px',
		//maxWidth: '990px',
		margin: 'auto',
	},
	headerContent: {
		backgroundColor: 'none',
		justifyContent: 'center',
		//alignItems: 'center',
		spacing: 1,
		padding: '41px 39px',
		flexDirection: 'column',
		textAlign: 'left',
	},
	vipContentText: {
		flex: 3,
		flexDirection: 'row',
		minWidth: '292px',
	},
	iconButton: {
		left: '5px',
		position: 'absolute',
		height: '25px',
	},
	buttonCollection: {
		marginLeft: '20px',
		width: '250px',
	},
	itemContent: {
		overflowX: 'hidden',
		width: 'calc( 100vw - 32px )',
		margin: 'auto',
	},
	headerTypo: {
		fontSize: '26px',
		fontWeight: 'normal',
		color: 'primary.dark',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	headerTypoSub: {
		fontWeight: 'normal',
		color: 'primary.dark',
	},
	vipContent: {
		m: 2,
		borderRadius: '7px',
		backgroundColor: '#141617',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: 'primary.main',
		padding: '30px',
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	vip: {
		color: colorOr,
		fontSize: '22px',
		fontWeight: 400,
	},
	vipMessage: {
		marginTop: '10px',
		fontSize: '15',
		fontWeight: 400,
		margin: '15px 0',
	},
	iconCard: {
		width: '65px',
		marginRight: '10px',
	},
	choiceBoxContent: { flexDirection: 'row', flexWrap: 'wrap' },
	choiceContent: {
		flex: 1,
		border: '1px solid white',
		borderRadius: '7px',
		margin: '15px',
		padding: '40px',
		backgroundColor: '#141617',
		flexWrap: 'wrap',
		minWidth: '300px',
	},
	cardContent: { flex: 0 },
	typoContent: { flex: 3 },
	buttonContent: { flex: 1, margin: 'auto' },
	vipButton: { lineHeight: '15px', minWidth: '250px' },
};

export default styles;

import { SxProps, Theme } from '@mui/system';
import { CSSProperties } from 'react';

const styles: {
	arrowIcon: SxProps<Theme>;
	sliderContainer: SxProps<Theme>;
	sliderWrapper: SxProps<Theme>;
	sliderPrevButton: SxProps<Theme>;
	sliderNextButton: SxProps<Theme>;
	sliderImage: CSSProperties;
} = {
	arrowIcon: {
		width: '40px',
		height: '40px',
	},
	sliderContainer: {
		display: 'block',
		overflowX: 'hidden',
		position: 'relative',
	},
	sliderWrapper: {
		position: 'relative',
		flexDirection: 'row',
		transition: 'right 1s ease',
	},
	sliderPrevButton: {
		zIndex: '10',
		color: 'white',
		width: '40px',
		height: '18vh',
		left: '0',
		position: 'absolute',
	},
	sliderNextButton: {
		zIndex: '10',
		color: 'white',
		fontSize: '60px',
		width: '40px',
		right: '0',
		position: 'absolute',
		height: '18vh',
		left: 'calc(100% - 64px)',
	},
	sliderImage: {
		marginLeft: 0,
		marginRight: 0,
		cursor: 'pointer',
		maxWidth: '79vw',
		maxHeight: '200vh',
	},
};

export default styles;

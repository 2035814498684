// Why.style.tsx

const styles = {
	backgroundContent: {
		//backgroundImage: 'url("/images/background.png")',
		//backgroundSize: 'cover',
		//backgroundPosition: 'center',
		paddingBottom: '40px',
	},
	blockContent: {
		direction: 'column',
		flexDirection: 'column',
		flex: 1,
		maxWidth: '1280px',
		margin: 'auto',
	},
	headerContent: {
		backgroundColor: 'none',
		justifyContent: 'center',
		spacing: 1,
		padding: '41px 39px',
		flexDirection: 'column',
		textAlign: 'left',
	},
	headerTypo: {
		fontSize: '26px',
		fontWeight: 'normal',
		color: 'primary.light',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	headerTypoSub: {
		fontWeight: 'normal',
		color: 'primary.light',
	},
};

export default styles;

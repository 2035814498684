import React, { useEffect, useState } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { useSession } from 'next-auth/react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { PaymentIntent } from '@stripe/stripe-js';
import { parseEther } from 'viem';
import { useConnectModal } from '@rainbow-me/rainbowkit';

import Carousel from './Carousel';
import Buy from './Buy';
import VipBox from './VipBox';
import Why from './Why';
import Pay from './Pay';
import PayRef from './PayRef';
import PayAccept from './PayAccept';
import PopIn from '../../components/PopIn';
import Slider from '../../components/Slider/Slider';
import homeStyle from './Home.style';
import IconCarte from './img/card.svg';
import IconCube from './img/cube.svg';
import Aviator from './img/bar.svg';
import AviatorRose from './img/101_rose.svg';
import { monuments } from '../../asset/monuments';
import { CurrencyType } from '../../utils/types/currency-type';
import { getFees } from '../../utils/stripe-helpers';
import { fetchPostJSON } from '../../utils/api-helpers';
import { MarketType } from '../../utils/types/market-type';
import { ProductType } from '../../utils/types/product-type';
import { fetchChangeRates } from '../../utils/rates';
import { MintNFTPreOrder } from '../MintNFTPreOrder';
import { fetchUserPreOrders } from '../../utils/account';
import { parsePreOrders } from '../../utils/helpers';
import { getPricesAndSupply } from '../../utils/preorders';
import Rainbow from '../Rainbow';
import PricesTag from '../PricesTag';
import What from './What';
import WhatVipBox from './WhatVipBox';

export default function Home() {
	const { t, i18n } = useTranslation(['home', 'header']);

	const nfts = monuments();

	// EUR is the default currency set in the smart contract templates
	const { rates } = fetchChangeRates(CurrencyType.EUR);

	const { currentSupply, saleIsActive, prices, isLoading: isLoadingPricesAndSupply } = getPricesAndSupply();
	const { connectModalOpen, openConnectModal } = useConnectModal();

	const { address, isConnected } = useAccount();
	const { data: session } = useSession();
	const balanceMatic = useBalance({
		address: address,
	});

	const [recipient, setRecipient] = useState(address);

	const [showCollection, setShowCollection] = useState(false);
	const [showCarrousel, setShowCarrousel] = useState(false);
	const [showBuy, setShowBuy] = useState(false);
	const [showPay, setShowPay] = useState(false);
	const [showWalletPayUI, setShowWalletPayUI] = useState(false);
	const [showPayRef, setShowPayRef] = useState(false);
	const [showPayAccept, setShowPayAccept] = useState(false);
	const [showBox, setShowBox] = useState(false);
	const [showRainbow, setShowRainbow] = useState(false);

	const [itemBuy, setItemBuy] = useState<Item>();
	const [currency, setCurrency] = useState<CurrencyType>(CurrencyType.EUR);
	const [itemPay, setItemPay] = useState<Item>();
	const [selectedProductType, setSelectedProductType] = useState<ProductType>();
	const [isPurchased, setIsPurchased] = useState<boolean>(false);

	const [piece, setPiece] = useState<Piece>();
	const [itemId, setItemId] = useState<number>(0);
	const [myNfts, setMyNfts] = useState<Piece[] | null>([]);
	const [myBoxes, setMyBoxes] = useState<Item[] | null>([]);

	const price = itemBuy?.price.euro ?? 19; // TODO change me and add useEffect to update priceInEuros
	const [priceInEuros, setPriceInEuros] = useState(price);
	const [priceInDollars, setPriceInDollars] = useState(price * rates?.USD);
	const [priceInMatic, setPriceInMatic] = useState(price * rates?.MATIC);
	const [feesInEuros, setFeesInEuros] = useState(getFees(priceInEuros));
	const [feesInDollars, setFeesInDollars] = useState(feesInEuros * rates?.USD);
	const [finalPriceInEuros, setFinalPriceInEuros] = useState(feesInEuros + priceInEuros);
	const [finalPriceInDollars, setFinalPriceInDollars] = useState(feesInDollars + priceInDollars);

	const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null);

	const {
		preOrders,
		error: errorPreOrders,
		isLoading: isLoadingPreOrders,
	} = fetchUserPreOrders({ owner: address, sortBy: 'creationTimestamp:asc' });

	useEffect(() => {
		if (showBuy && (!saleIsActive || !isConnected || session?.user === null)) {
			if (!connectModalOpen && !isConnected && !showRainbow) {
				setShowRainbow(true);
			}
		}
	}, [showBuy, saleIsActive, isConnected, session, showRainbow, connectModalOpen]);

	useEffect(() => {
		if (connectModalOpen && !isConnected && showRainbow) {
			setShowRainbow(false);
		}
	}, [isConnected, connectModalOpen, showRainbow]);

	useEffect(() => {
		if (isConnected && !isLoadingPreOrders && preOrders && nfts) {
			const items = parsePreOrders(preOrders.results, nfts);
			if (items?.myNfts) {
				setMyNfts(items.myNfts);
			}
			if (items?.myBoxes) {
				setMyBoxes(items.myBoxes);
			}
		}
	}, [isLoadingPreOrders, preOrders, isConnected]);

	useEffect(() => {
		if (price && rates) {
			setPriceInDollars(price * rates?.USD);
			setPriceInMatic(price * rates?.MATIC);
		}
	}, [price, rates]);

	useEffect(() => {
		if (address && isConnected) {
			setRecipient(address);
		}
	}, [address, isConnected]);

	useEffect(() => {
		if (price && priceInEuros && priceInDollars && rates) {
			setPriceInEuros(price);
			const feesEur = getFees(priceInEuros);
			const feesUsd = feesEur * rates?.USD;
			setFeesInEuros(feesEur);
			setFeesInDollars(feesUsd);
			setFinalPriceInDollars(priceInDollars + feesUsd);
			setFinalPriceInEuros(priceInEuros + feesEur);
		}
	}, [price, priceInEuros, priceInDollars, rates, paymentIntent]);

	function canBuyWithCryptoWallet() {
		return saleIsActive && isConnected && address && finalPriceInEuros > 0 && priceInMatic > 0; // && Number(balanceMatic.data?.formatted) >= priceInMatic;
	}

	const canBuyWithStripe = () => {
		return !!(saleIsActive && itemBuy && isConnected && address && finalPriceInEuros > 0);
	};

	const buyWithStripe = (item: Item, currency: CurrencyType, productType: ProductType) => {
		if (canBuyWithStripe()) {
			setShowPay(true);
			handlePaymentIntents(item, currency, productType);
		}
	};

	const handlePaymentIntents = (item: Item, currency: CurrencyType, productType: ProductType) => {
		fetchPostJSON('/api/payment_intents', {
			userEmail: session?.user?.email,
			userName: session?.user?.name,
			userLanguage: i18n.language,
			currency: currency,
			amount: currency === CurrencyType.EUR ? finalPriceInEuros : finalPriceInDollars,
			fees: currency === CurrencyType.EUR ? feesInEuros : feesInDollars,
			orderDescription: `${MarketType.PreOrder} ${ProductType[productType]} - ${item.id} - ${item.name}`,
			productType: productType,
			marketType: MarketType.PreOrder,
			masterKey: item.id,
			collectorKey1: item.id,
			collectorKey2: item.id,
			recipient: recipient,
			quantity: item.qte ?? 1,
		})
			.then((data) => {
				setPaymentIntent(data);
			})
			.catch((error) => {
				console.error('Error handlePaymentIntents:', error);
				// TODO trigger sweet alert, close Modal
			});
	};

	return (
		<Stack sx={homeStyle.blockContent}>
			<Box display="flex" justifyContent="center" alignItems="center" minHeight="50px">
				<Image src={Aviator} alt={'Logo 101'} width={193} height={17}></Image>
			</Box>
			{/* HEADER Message Content */}
			<Stack sx={homeStyle.headerContent}>
				<Typography sx={homeStyle.headerTypo} variant="h1">
					{t('preview1', { ns: 'header' })}
				</Typography>
				<Typography sx={homeStyle.headerTypoSub} variant="h1" marginTop={1}>
					{t('preview2', { ns: 'header' })}
				</Typography>
				<Typography sx={homeStyle.headerTypoSub} variant="h1" marginTop={1}>
					{t('preview3', { ns: 'header' })}{' '}
					<Image style={{ marginLeft: 15 }} src={AviatorRose} alt={'Logo 101 special'} width={193} height={17}></Image>
				</Typography>
			</Stack>

			{/* TAB Content */}
			<Stack sx={homeStyle.buttonContent}>
				<Stack flexDirection="row" sx={homeStyle.tabContent}>
					<Button
						className="buttonTab"
						onClick={() => setShowCollection(false)}
						variant={showCollection ? 'outlined' : 'contained'}
						sx={homeStyle.buttonGalerie}
					>
						<Image
							src={IconCube}
							width={0}
							height={25}
							alt="card"
							style={
								showCollection
									? { left: '15px', position: 'absolute', height: '25px', filter: 'invert(70%) sepia(70%)' }
									: { left: '15px', position: 'absolute', height: '25px' }
							}
						/>
						{t('GALERIE')}
					</Button>
					<Button
						className="buttonTab"
						onClick={() => setShowCollection(true)}
						variant={showCollection ? 'contained' : 'outlined'}
						sx={homeStyle.buttonCollection}
					>
						<Image
							src={IconCarte}
							width={0}
							height={25}
							alt="card"
							style={
								showCollection
									? { left: '10px', position: 'absolute', height: '25px', filter: 'contrast(100%) sepia(100%)' }
									: { left: '10px', position: 'absolute', height: '25px' }
							}
						/>
						{t('MA_COLLECTION')}
					</Button>
				</Stack>
			</Stack>

			{/* Collection Gallery Content */}
			<Stack>
				{showCollection ? (
					<>
						{!isConnected && (
							<Stack sx={homeStyle.headerContent}>
								<Typography sx={homeStyle.headerTypo} variant="h1" marginTop={5}>
									{t('connect-your-wallet', { ns: 'home' })}
								</Typography>
							</Stack>
						)}
						{myNfts?.length === 0 && myBoxes?.length === 0 && (
							<Stack sx={homeStyle.headerContent}>
								<Typography color={'primary.light'} variant="h2" marginTop={1} marginBottom={5}>
									{t('empty-collection', { ns: 'home' })}
								</Typography>
							</Stack>
						)}
						{myNfts &&
							myNfts.map((p, index) => (
								<Stack key={index} sx={homeStyle.itemContent}>
									<Slider
										key={index}
										title={p.title}
										items={p.items}
										onSelect={(item) => {
											setPiece(p);
											setItemId(item.id);
											setIsPurchased(true);
											setShowCarrousel(true);
										}}
									/>
								</Stack>
							))}
						{myBoxes && (
							<Stack key={'boxes'} sx={homeStyle.itemContent}>
								<Slider key={'boxes'} title={'VIP DAC BOX'} items={myBoxes} onSelect={() => {}} />
							</Stack>
						)}
					</>
				) : (
					<>
						{nfts &&
							nfts.map((p, index) => (
								<Stack key={index} sx={homeStyle.itemContent}>
									<Slider
										key={index}
										title={p.title}
										items={p.items}
										onSelect={(item) => {
											setPiece(p);
											setItemId(item.id);
											setIsPurchased(false);
											setShowCarrousel(true);
										}}
									/>
								</Stack>
							))}
						<Box display="flex" justifyContent="center" alignItems="center" minHeight="50px">
							{process.env.NEXT_PUBLIC_SALE_IS_ACTIVE === 'true' ? (
								<Stack sx={homeStyle.priceContent}>
									<Typography variant="h1" marginTop={1} fontSize={'2.2rem'} color={'primary.main'}>
										<PricesTag productType={ProductType.DAC} />
									</Typography>
									<Typography sx={homeStyle.headerTypoSub} variant="h2" marginTop={1} marginBottom={3}>
										{t('clicToBuy', { ns: 'home' })}
									</Typography>
								</Stack>
							) : (
								<Stack sx={homeStyle.priceContent}>
									<Typography variant="h1" fontSize={'2.2rem'} color={'primary.main'} marginTop={3} marginBottom={5}>
										{t('salesAreClosed', { ns: 'home' })}
									</Typography>
								</Stack>
							)}
						</Box>
					</>
				)}
			</Stack>

			<What />

			<VipBox
				onSelect={(item: Item, productType: ProductType) => {
					setSelectedProductType(productType);
					setItemBuy(item);
					setShowBuy(true);
				}}
			/>

			<WhatVipBox />

			<Why />

			{/* Carousel POPIN */}
			{piece && (
				<PopIn onClose={() => setShowCarrousel(false)} open={showCarrousel} fullScreen>
					<Carousel
						items={piece.items.filter((item) => item.id === itemId)}
						id={itemId}
						onSelect={(item: Item) => {
							setSelectedProductType(ProductType.DAC);
							setItemBuy(item);
							setShowBuy(true);
						}}
						isPurchased={isPurchased}
					/>
				</PopIn>
			)}

			{/* Rainbow POPIN */}
			<PopIn onClose={() => setShowRainbow(false)} open={showRainbow}>
				<Rainbow
					onAccept={() => {
						if (openConnectModal && !connectModalOpen) {
							openConnectModal();
							setShowRainbow(false);
						}
					}}
				/>
			</PopIn>

			{/* Select Buy POPIN */}
			{itemBuy && (
				<PopIn onClose={() => setShowBuy(false)} open={showBuy}>
					<Buy
						itemBuy={itemBuy}
						selectedProductType={selectedProductType as ProductType}
						onSelect={(item: Item, currency: CurrencyType) => {
							setItemPay(item);
							setCurrency(currency);
							if (currency === CurrencyType.EUR || currency === CurrencyType.USD) {
								buyWithStripe(item, currency, selectedProductType as ProductType);
							} else {
								setShowWalletPayUI(true);
							}
							setShowBuy(false);
						}}
					/>
				</PopIn>
			)}

			{/* WalletCrypto Pay POPIN */}
			{itemPay && (
				<PopIn onClose={() => setShowWalletPayUI(false)} open={showWalletPayUI} fullScreen>
					<MintNFTPreOrder
						item={itemPay}
						enabled={canBuyWithCryptoWallet() as boolean}
						recipient={recipient as `0x${string}`}
						orderId={itemPay.id}
						productType={itemPay.productType}
						priceInWei={parseEther(`${priceInMatic}`)}
					/>
				</PopIn>
			)}

			{/* Stripe Pay POPIN */}
			{itemPay && (
				<PopIn onClose={() => setShowPay(false)} open={showPay}>
					<Pay itemPay={itemPay} currency={currency} paymentIntent={paymentIntent} />
				</PopIn>
			)}

			{/* Stripe PayAccept POPIN */}
			<PopIn onClose={() => setShowPayRef(false)} open={showPayRef} fullScreen>
				<PayRef onBack={() => setShowPayRef(false)} />
			</PopIn>

			{/* Stripe PayAccept POPIN */}
			<PopIn onClose={() => setShowPayAccept(false)} open={showPayAccept} fullScreen>
				<PayAccept onBack={() => setShowPayAccept(false)} />
			</PopIn>

			{/* Box POPIN */}
			<PopIn onClose={() => setShowBox(false)} open={showBox} fullScreen>
				<VipBox onSelect={() => {}} />
			</PopIn>
		</Stack>
	);
}

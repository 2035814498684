import { Checkbox } from '@mui/material';
import { useField } from 'formik';

type Props = {
	name: string;
};

export default function CheckBox({ name, ...rest }: Props) {
	const [field] = useField<string>({ name });
	return <Checkbox id={name} checked={field.value as unknown as boolean} {...field} {...rest} />;
}

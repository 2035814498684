import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import style from './WhatVipBox.style';
interface WhyProps {}

export default function WhatVipBox({}: WhyProps) {
	const { t } = useTranslation(['home', 'header']);

	return (
		<Box sx={style.backgroundContent}>
			<Stack sx={style.blockContent}>
				<Stack sx={style.headerContent}>
					<Typography sx={style.headerTypo} variant="h1">
						{t('whatVipBox.headerTitle')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={3}>
						{t('whatVipBox.arg1Title')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatVipBox.arg11')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatVipBox.arg12')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatVipBox.arg13')}
					</Typography>
					<Typography sx={style.headerTypo} variant="h1" marginTop={4}>
						{t('whatVipBox.headerTitle2')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={3}>
						{t('whatVipBox.arg2Title')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatVipBox.arg21')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={1}>
						{t('whatVipBox.arg22')}
					</Typography>
					<Typography sx={style.headerTypoSub} variant="h2" marginTop={3}>
						{t('whatVipBox.finalArg')}
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
}

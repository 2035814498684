import axios from 'axios';
import useSWR from 'swr';

const fetcher = (url) => axios.get(url).then((res) => res.data);

export function fetchChangeRates(currencyIn) {
	const { data, error, isLoading, isValidating, mutate } = useSWR(
		currencyIn ? `/api/get_rates?currencyIn=${currencyIn}` : null,
		fetcher
	);
	return {
		rates: data,
		error: error,
		isLoading: isLoading,
		isValidating: isValidating,
		mutate: mutate,
	};
}

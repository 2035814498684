import React, { useRef, useState } from 'react';
import { Box, Button, Stack, Typography, TextField } from '@mui/material';
import { Form, Formik, useField } from 'formik';
import { useTranslation } from 'next-i18next';
import * as Yup from 'yup';
import { isMobile } from 'react-device-detect';
import Captcha from 'react-google-recaptcha';

import style from './Newsletter.style';
import { colorOr } from '../../themes/theme';

interface NewsletterProps {}

const MyTextField = (props: any) => {
	const [field, meta] = useField(props);
	const errorText = meta.error && meta.touched ? meta.error : '';
	return <TextField {...props} {...field} helperText={errorText} error={!!errorText} />;
};

export default function Newsletter({}: NewsletterProps) {
	const { t } = useTranslation(['landing', 'header']);
	const [message, setMessage] = useState<string>('');
	const captchaRef = useRef<Captcha>(null);

	return (
		<Box sx={style.contained}>
			<Stack sx={style.stack}>
				<Typography sx={style.text} variant="h3">
					{t('newsletterText')}
				</Typography>
				<Formik
					validateOnChange={false}
					initialValues={{
						email: '',
					}}
					validationSchema={Yup.object({
						email: Yup.string().email(t('newsletterEmailInvalid')).required(t('newsletterEmailNeeded')),
					})}
					onSubmit={async (values, { setSubmitting }) => {
						// Get the token
						const captcha = await captchaRef.current?.executeAsync();
						const res = await fetch('/api/newsletter', {
							method: 'POST',
							body: JSON.stringify({ email: values.email, listId: 4, captcha }),
							headers: { 'Content-type': 'application/json' },
						});
						if (res?.status === 200) {
							setMessage(t('newsletterSuccess'));
						} else {
							setMessage(t('newsletterError'));
						}
						setSubmitting(false);
					}}
				>
					{({ isSubmitting }) => (
						<Form>
							<Captcha ref={captchaRef} size="invisible" sitekey={process.env.NEXT_PUBLIC_RECAPTCHA!} />
							<Stack direction="row" justifyContent="center" paddingTop={3}>
								<Stack direction="column" justifyContent="center">
									<MyTextField
										fullWidth
										focused
										id="email"
										name="email"
										placeholder="jane@acme.com"
										type="email"
										label="Email"
										variant="outlined"
										size="small"
										InputProps={{
											style: {
												color: colorOr,
												borderRadius: '25px',
												backgroundColor: '#000000',
												minWidth: isMobile ? '100%' : '350px',
											},
										}}
									/>
								</Stack>
								<Button
									sx={{ width: 'auto', marginLeft: '20px' }}
									variant="outlined"
									type="submit"
									disabled={isSubmitting}
								>
									{t('newsletterSend')}
								</Button>
							</Stack>
							{message && (
								<Typography color="whitesmoke" fontSize={'1.2em'} paddingTop={2}>
									{message}
								</Typography>
							)}
						</Form>
					)}
				</Formik>
			</Stack>
		</Box>
	);
}

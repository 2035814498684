import { Box, Modal, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';

import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

type Props = {
	open: boolean;
	onClose: () => void;
	children?: React.ReactNode;
};

export default function PopInLanding({ open, onClose, children }: Props) {
	return (
		<>
			{open && (
				<Box
					sx={{
						maxHeight: '100vh',
						overflow: 'auto',
						backdropFilter: 'blur(12px)',
						position: 'fixed',
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
					}}
				></Box>
			)}
			<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
				<Modal
					open={open}
					onClose={onClose}
					sx={
						isMobile
							? {
									width: '320px',
									height: '380px',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									border: '3px solid #ad9579',
									zIndex: '999',
									borderRadius: '20px',
									background: '#000000',
							  }
							: {
									width: '500px',
									height: '450px',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									border: '3px solid #ad9579',
									zIndex: '999',
									borderRadius: '20px',
									background: '#000000',
							  }
					}
					componentsProps={{
						backdrop: {
							style: {
								borderRadius: '20px',
								backdropFilter: 'blur(12px)',
							},
						},
					}}
				>
					<>
						<CancelIcon
							sx={{
								fontSize: 45,
								right: '-23px',
								position: 'absolute',
								top: '-23px',
								color: '#ad9579',
								cursor: 'pointer',
							}}
							onClick={onClose}
						/>
						<Stack direction="column" justifyContent="center" alignItems="center">
							{children}
						</Stack>
					</>
				</Modal>
			</Stack>
		</>
	);
}

PopInLanding.defaultProps = {
	children: <div />,
	title: undefined,
	fullScreen: false,
};
